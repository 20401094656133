export const randomString = () => Math.random().toString(36).substring(2, 36) + Math.random().toString(36).substring(2, 20);

export const validDate = date => date.year && date.month && date.day;

export const formatDate = date => `${date.year}-${date.month}-${date.day}`;

export const displayDate = (dateStr, includeTime = false) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = 1 + date.getMonth();
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  let result = `${year}年${month}月${day}日`;
  if (includeTime) result += ` ${hour}:${minute}`;
  return result;
};

export const postTypes = {
  default: '通常',
  important: '重要',
  child: '子育て',
  event: 'イベント',
  emergency: '緊急・災害',
};

export const deconstructDate = date => {
  const split = date.split('-');
  return { year: split[0], month: split[1], day: split[2] };
};

export const clippedText = str => {
  const ending = str.length < 65 ? '' : '...';
  return `${str.substring(0, 65)}${ending}`;
};

export const generateTags = ({
  interests = [],
  genders = [],
  ageGroups = [],
  areas = [],
}) => {
  const interest_tags = interests.map(interest => ({ tag_type: 'interest', name: interest }));
  const gender_tags = genders.map(gender => ({ tag_type: 'gender', name: gender }));
  const age_group_tags = ageGroups.map(ageGroup => ({ tag_type: 'age_group', name: ageGroup }));
  const area_tags = areas.map(area => ({ tag_type: 'area', name: area }));
  return [ ...interest_tags, ...gender_tags, ...age_group_tags, ...area_tags ];
};
