import React, { useEffect, useState } from 'react';
import { withContext } from '../../context';
import { apiDelete, apiGet, apiPost, apiPut } from '../../utils/api';
import { displayDate } from '../../utils/utils';
import { Modal } from '../modal/modal';
import { PageTitle } from '../shared';

const OfficeUsers = ({ context, location }) => {
  const [officeUsers, setOfficeUsers] = useState([]);
  const [officeUser, setOfficeUser] = useState(null);
  const [modal, setModal] = useState(false);

  const { authToken, office_id } = context.appData;

  useEffect(() => {
    const fetchOfficeUsers = async () => {
      const res = await apiGet({ authToken, endpoint: `/offices/${office_id}/office_users` });
      !res.error ? setOfficeUsers(res) : alert(res.error);
    };
    fetchOfficeUsers();
  }, []);

  const addUser = async () => {
    const res = await apiPost({
      authToken,
      endpoint: `/offices/${office_id}/office_users`,
      data: { office_user: officeUser },
    });
    !res.error
      ? setOfficeUsers([ ...officeUsers, res ])
      : alert(res.error);
    closeModal();
  };

  const updateUser = async () => {
    const res = await apiPut({
      authToken,
      endpoint: `/offices/${office_id}/office_users/${officeUser.id}`,
      data: { office_user: officeUser }
    });
    if (!res.error) {
      const newOfficeUsers = [ ...officeUsers ];
      const index = newOfficeUsers.findIndex(user => user.id === officeUser.id);
      newOfficeUsers[index] = res;
      setOfficeUsers(newOfficeUsers);
      closeModal();
    } else {
      alert(res.error);
      closeModal();
    }
  };

  const deleteUser = async (officeUserId) => {
    const res = await apiDelete({
      authToken,
      endpoint: `/offices/${office_id}/office_users/${officeUserId}`
    });
    if (!res.error) {
      const newOfficeUsers = [ ...officeUsers ];
      const index = newOfficeUsers.findIndex(user => user.id === officeUserId);
      newOfficeUsers.splice(index, 1);
      setOfficeUsers(newOfficeUsers);
      closeModal();
    } else {
      alert(res.error);
      closeModal();
    }
  };

  const invalidData = () => {
    const { current_password, new_password, new_password_confirmation } = officeUser;
    if (!current_password && !new_password && !new_password_confirmation) {
      return !officeUser.username || !officeUser.email || !officeUser.role;
    } else if (!!new_password) {
      return !officeUser.username || !officeUser.email || !officeUser.role || !current_password || new_password !== new_password_confirmation;
    }
  };

  const closeModal = () => {
    setModal(false);
    setTimeout(() => setOfficeUser(null), 300);
  };

  const roleMap = [ '', '管理者', '通常' ];

  return (
    <div className="uk-flex-1">
      <div className="uk-container uk-container-small">
        <div className="uk-text-center">
          <PageTitle title="ユーザー管理" />
          <div className="uk-text-right">
            <button
              className="uk-button uk-button-small uk-button-primary uk-button-rounded"
              onClick={() => {
                setModal(true);
                setOfficeUser({});
              }}>
              ユーザーを追加
            </button>
          </div>
          <table className="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-small uk-text-left">
            <thead>
              <tr>
                <th>ユーザー名</th>
                <th>メールアドレス</th>
                <th>権限</th>
                <th>追加日</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {officeUsers.map((officeUser, i) => (
                <tr key={`officeUsers-officeUser-${i}`}>
                  <td>{officeUser.username}</td>
                  <td>{officeUser.email}</td>
                  <td>{roleMap[officeUser.role]}</td>
                  <td>{displayDate(officeUser.created_at)}</td>
                  <td className="uk-text-right">
                    <button
                      className="uk-button uk-button-link"
                      onClick={() => {
                        setModal(true);
                        setOfficeUser(officeUser);
                      }}>
                      編集
                    </button>
                    {context.appData.role < 2 && (
                      <button
                        className="uk-button uk-button-link uk-text-danger uk-margin-small-left"
                        onClick={() => {
                          if (window.confirm("削除してもよろしいですか？")) deleteUser(officeUser.id);
                        }}>
                        削除
                      </button>
                    )}
                  </td>
                </tr>
              ))}
              {officeUsers.length === 0 && (
                <tr>
                  <td colSpan={5} className="uk-text-center">
                    <span className="uk-text-small uk-text-meta">表示できるユーザーがいません</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={modal}>
        <div className="uk-modal-header uk-flex uk-flex-between">
          <h4 className="uk-margin-remove">ユーザー詳細</h4>
          <button
            className="uk-button-icon"
            data-uk-icon="close"
            onClick={closeModal} />
        </div>
        <div className="uk-modal-body uk-flex-1">
          {officeUser && (
            <>
              <div className="uk-flex uk-flex-row">
                <label className="uk-form-label uk-width-small uk-flex-none uk-margin-xsmall-top">ユーザー名</label>
                <input
                  type="text"
                  className="uk-input"
                  placeholder="ユーザー名"
                  value={officeUser.username} disabled={officeUser.id}
                  onChange={e => setOfficeUser({ ...officeUser, username: e.target.value })} />
              </div>
              <div className="uk-flex uk-flex-row uk-margin-top">
                <label className="uk-form-label uk-width-small uk-flex-none uk-margin-xsmall-top">メールアドレス</label>
                <input
                  type="email"
                  className="uk-input"
                  placeholder="メールアドレス"
                  value={officeUser.email} disabled={officeUser.id}
                  onChange={e => setOfficeUser({ ...officeUser, email: e.target.value }) } />
              </div>
              <div className="uk-flex uk-flex-row uk-margin-top">
                <label className="uk-form-label uk-width-small uk-flex-none uk-margin-xsmall-top">権限</label>
                <select
                  className="uk-select" value={officeUser.role || 0}
                  onChange={e => setOfficeUser({ ...officeUser, role: parseInt(e.target.value) })}>
                  <option value="0" disabled>選択してください</option>  
                  <option value="1">管理者</option>
                  <option value="2">通常ユーザー</option>
                </select>
              </div>
              {!officeUser.id && (
                <div className="uk-flex uk-flex-row uk-margin-top">
                  <label className="uk-form-label uk-width-small uk-flex-none uk-margin-xsmall-top">パスワード</label>
                  <input
                    type="password"
                    className="uk-input"
                    placeholder="パスワード"
                    value={officeUser.password} disabled={officeUser.id}
                    onChange={e => setOfficeUser({ ...officeUser, password: e.target.value, password_confirmation: e.target.value }) } />
                </div>
              )}
            </>
          )}
        </div>
        <div className="uk-modal-footer uk-flex uk-flex-row">
          {officeUser && (
            <>
              <button
                className="uk-button uk-button-default uk-button-rounded uk-margin-auto-left uk-margin-small-right"
                onClick={closeModal}>
                キャンセル
              </button>
              <button
                className="uk-button uk-button-primary uk-button-rounded"
                disabled={invalidData()}
                onClick={officeUser.id ? updateUser : addUser}>
                保存
              </button>
            </>
          )}
        </div>
      </Modal>

    </div>
  );
};

const OfficeUsersWithContext = withContext(OfficeUsers);
export { OfficeUsersWithContext as OfficeUsers };
