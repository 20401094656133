import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../shared';
import { locations } from '../../utils/locations';
import UIkit from 'uikit';
import { apiPost } from '../../utils/api';
import { withContext } from '../../context';
import { Loading } from '../loading/loading';

const NewAccount = ({ context }) => {
  const { authToken } = context.appData;
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [prefecture, setPrefecture] = useState('都道府県を選択してください');

  const prefectures = Object.keys(locations);
  const areas = locations[prefecture];

  useEffect(() => {
    if (context.appData.role !== 0) history.replace('/');
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const { name, phone_number, area, url, email, username, password, password_confirmation } = e.target.elements;
    if (password.value !== password_confirmation.value) {
      UIkit.notification("パスワードが一致していません", 'danger');
    } else {
      const data = {
        office: {
          name: name.value,
          phone_number: phone_number.value,
          prefecture,
          area: area.value,
          url: url.value,
          office_users_attributes: [
            {
              email: email.value,
              username: username.value,
              role: 1,
              password: password.value,
              password_confirmation: password_confirmation.value
            }
          ],
        }
      };
      const res = await apiPost({ authToken, endpoint: '/offices', data });
      !res.error
        ? history.push('/admin')
        : alert('アカウント作成中のエラーが発生しました。システム管理者に連絡してください。');
    }
  };

  return (
    <div className="uk-flex-1">
      <div className="uk-container uk-container-small">
        <div className="uk-text-center">
          <PageTitle title="新規アカウント作成" />
        </div>
        <form
          onSubmit={handleSubmit}
          className="uk-form-horizontal">
          <h4>自治体情報</h4>
          <div>
            <label className="uk-form-label">自治体名</label>
            <div className="uk-form-controls">
              <input name="name" className="uk-input" type="text" placeholder="自治体名" required />
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">電話番号</label>
            <div className="uk-form-controls">
              <input name="phone_number" className="uk-input" type="text" placeholder="電話番号" required />
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">所在地</label>
            <div className="uk-form-controls">
              <select
                className="uk-select" defaultValue="都道府県を選択してください"
                onChange={e => setPrefecture(e.target.value)} required>
                {prefectures.map((prefecture, i) => (
                  <option key={`${i}-${prefecture}`} value={prefecture}>{prefecture}</option>
                ))}
              </select>
              <select
                name="area" required
                className="uk-select uk-margin-top" placeholder="市区町村を選択してください">
                {areas.map((area, i) => (
                  <option key={`${i}-${area}`} value={area}>{area}</option>
                ))}
              </select>
            </div>
            <div className="uk-margin-top">
              <label className="uk-form-label">WEBサイト</label>
              <div className="uk-form-controls">
                <input name="url" className="uk-input" type="text" placeholder="https://" />
              </div>
            </div>
          </div>
          <h4>ユーザー情報</h4>
          <div>
            <label className="uk-form-label">メールアドレス</label>
            <div className="uk-form-controls">
              <input name="email" className="uk-input" type="text" placeholder="メールアドレス" required />
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">表示名</label>
            <div className="uk-form-controls">
              <input name="username" className="uk-input" type="text" placeholder="表示名" required />
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">パスワード</label>
            <div className="uk-form-controls">
              <input name="password" className="uk-input" type="password" placeholder="パスワード" required />
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">パスワードの確認</label>
            <div className="uk-form-controls">
              <input name="password_confirmation" className="uk-input" type="password" placeholder="パスワードの確認" required />
            </div>
          </div>
          <div className="uk-flex uk-flex-row uk-flex-right uk-flex-middle uk-margin-large-top">
            <button
              className="uk-button uk-button-default uk-margin-small-right uk-button-rounded"
              onClick={() => history.goBack()}>
              キャンセル
            </button>
            <button className="uk-button uk-button-primary uk-button-rounded" disabled={submitting}>
              アカウントを作成する
            </button>
          </div>
        </form>
      </div>
      {submitting && <Loading />}
    </div>
  );
};

const NewAccountWithContext = withContext(NewAccount);
export { NewAccountWithContext as NewAccount };
