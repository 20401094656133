import React from 'react';

const PageTitle = ({ title }) => (
  <div className="uk-margin-medium-top uk-margin-medium-bottom">
    <div className="page-title">
      <h3>{title}</h3>
    </div>
  </div>
);

export { PageTitle };
