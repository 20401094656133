import { apiPost } from "./api";

const saveToSpaces = async (res, file, fileName) => {
  const response = await fetch(res.url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'public-read',
    },
    body: file,
  });
  if (response.ok) {
    return `${res.base}${fileName}`;
  } else {
    return null;
  }
};

export const uploadImage = async ({ authToken, file }) => {
  const res = await apiPost({
    authToken,
    endpoint: '/assets/presigned_url',
    data: { file_name: file.name, file_type: file.type },
  });
  if (res) {
    const result = await saveToSpaces(res, file, file.name);
    return result;
  } else {
    return null;
  }
};

export const localImageUrl = file => {
  return window.URL.createObjectURL(file);
}
