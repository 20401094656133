import React, { Fragment } from 'react';
import { withContext } from '../../context';
import { MenuItem, PageTitle } from '../shared';

const MainMenu = ({ context }) => {
  const role = context.appData.role;

  return (
    <div className="uk-flex-1">
      <div className="uk-container uk-container-small">
        <div className="uk-text-center">
          <PageTitle title="メインメニュー" />
          <div className="uk-flex uk-flex-column uk-flex-middle">
            <MenuItem to="/posts/new">
              <h4 className="uk-margin-remove">新規投稿の作成</h4>
            </MenuItem>
            <MenuItem to="/posts" className="uk-margin-top">
              <h4 className="uk-margin-remove">投稿一覧</h4>
            </MenuItem>
            {role !== 0 && (
              <MenuItem to="/user" className="uk-margin-top">
                <h4 className="uk-margin-remove">ユーザー情報の変更</h4>
              </MenuItem>
            )}

            {[0, 1].includes(role) && (
              <Fragment>
                <p className="uk-margin-medium-top uk-text-meta">下記は管理権限を付与されたユーザーのみアクセスできます。</p>
                <MenuItem to="/settings">
                  <h4 className="uk-margin-remove">基本設定</h4>
                </MenuItem>
                <MenuItem to="/trash" className="uk-margin-top">
                  <h4 className="uk-margin-remove">ごみ出し情報設定</h4>
                </MenuItem>
                <MenuItem to="/scheduled_posts" className="uk-margin-top">
                  <h4 className="uk-margin-remove">子育て情報等定期配信設定</h4>
                </MenuItem>
                <MenuItem to="/users" className="uk-margin-top">
                  <h4 className="uk-margin-remove">ユーザー管理</h4>
                </MenuItem>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MainMenuWithContext = withContext(MainMenu);
export { MainMenuWithContext as MainMenu };
