import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { withContext } from '../../context';
import { apiGet, apiPost, apiPut } from '../../utils/api';
import { uploadImage } from '../../utils/images';
import { allAgeGroups, allAges, allGenders, allInterests } from '../../utils/options';
import { generateTags, formatDate, deconstructDate } from '../../utils/utils';
import { PageTitle } from '../shared';
import { locations as allLocations } from '../../utils/locations';
import { Loading } from '../loading/loading';
import { ImageCropper } from '../imageCropper/imageCropper';

const NewOrEdit = ({ context, location }) => {
  const history = useHistory();
  const { post_id } = useParams();
  const { authToken, office_id } = context.appData;

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [primaryLocation, setPrimaryLocation] = useState(null);

  const [post, setPost] = useState({
    post_type: 'default',
    title: '',
    body: '',
    remarks: '',
    url: '',
    start_date: '',
    end_date: '',
    venue: '',
    capacity: 0,
    images_attributes: [],
  });

  const [tags, setTags] = useState({ ageGroups: [], genders: [], interests: [], areas: [] });
  const [tmpImage, setTmpImage] = useState(null);
  const [deleteImages, setDeleteImages] = useState([]);
  const [dateType, setDateType] = useState('range');
  const [startDate, setStartDate] = useState({});
  const [endDate, setEndDate] = useState({});
  const [locations, setLocations] = useState({ type: 'allLocations', prefecture: '', area: '', neighborhood: '', school_district: '', neighborhoods: [], school_districts: [] });

  useEffect(() => {
    if (post_id && !location.data) {
      const fetchPost = async () => {
        const res = await apiGet({ authToken, endpoint: `/offices/${office_id}/posts/${post_id}` });
        !res.error ? deconstructPostData(res) : alert(res.error);
      };
      fetchPost();
    } else if (post_id && location.data) {
      deconstructPostData(location.data);
    }
    const fetchLocations = async () => {
      const res = await apiGet({ authToken, endpoint: `/offices/${office_id}/locations` });
      if (!res.error) {
        mapLocations(res.locations);
        setPrimaryLocation(res.location);
      }
    };
    fetchLocations();
  }, []);

  const mapLocations = locs => {
    const newLocations = { ...locations };
    locs.forEach(loc => !!loc.neighborhood ? newLocations.neighborhoods.push(loc) : newLocations.school_districts.push(loc));
    setLocations(newLocations);
    setLoading(false);
  };

  const deconstructPostData = data => {
    const { images, tags: postTags, ...postData } = data;
    const newTags = { ...tags };

    postTags.forEach(tag => {
      switch (tag.tag_type) {
        case 'interest':
          newTags.interests.push(tag.name);
          break;
        case 'gender':
          newTags.genders.push(tag.name);
          break;
        case 'age_group':
          newTags.ageGroups.push(tag.name);
          break;
        case 'area':
          newTags.areas.push(tag.name);
          break;
        default:
          break;
      }
    });

    if (postData.post_type === 'event') {
      setStartDate(deconstructDate(postData.start_date));
      postData.end_date ? setEndDate(deconstructDate(postData.end_date)) : setDateType('single');
    }

    setPost({ ...postData, images_attributes: images });
  };

  const safeToSubmit = () => {
    let result = false;
    if (['default', 'emergency', 'child', 'important'].includes(post.post_type)) {
      result = post.body && post.body.length > 0;
    } else if (post.post_type === 'event') {
      if (dateType === 'range') {
        result = (post.body && post.body.length > 0)
          && post.title
          && (startDate.year && startDate.month && startDate.day)
          && (endDate.year && endDate.month && endDate.day);
      } else {
        result = (post.body && post.body.length > 0)
          && post.title
          && (startDate.year && startDate.month && startDate.day);
      }
    }
    return result;
  };

  const addImage = imgObj => {
    const newPost = { ...post };
    newPost.images_attributes.push({ url: imgObj });
    setPost(newPost);
    setTmpImage(null);
  };

  const removeImage = (image, position) => {
    const newPost = { ...post };
    const target = newPost.images_attributes[position];
    if (target.id) {
      setDeleteImages([ ...deleteImages, image.url ]);
      image._destroy = true;
    } else {
      newPost.images_attributes.splice(position, 1);
    }
    setPost(newPost);
  };

  const updateTag = (tagType, tag) => {
    const newTags = { ...tags };
    const index = newTags[tagType].indexOf(tag);
    index > -1 ? newTags[tagType].splice(index, 1) : newTags[tagType].push(tag);
    setTags(newTags);
  };

  const submit = async () => {
    setSubmitting(true);
    const data = { ...post };
    
    if (post.post_type === 'event') {
      data.start_date = formatDate(startDate);
      if (dateType === 'range') data.end_date = formatDate(endDate);
    }

    const images = [];
    for (let i = 0; i < post.images_attributes.length; i++) {
      const image = post.images_attributes[i];
      if (!image.id) {
        console.log(image);
        const url = await uploadImage({ authToken, fileName: image.url.file.name, file: image.url.file });
        if (!!url) images.push({ url });
      } else {
        images.push(image);
      }
    }
    data.images_attributes = images;

    const generatedTags = generateTags(tags);
    if (!post_id) {
      generatedTags.push({ tag_type: 'area', name: `${primaryLocation.prefecture}${primaryLocation.area}`, weight: 1 });
    }

    const submitData = { post: data, tags: generatedTags, delete_images: deleteImages };
    const res = submitData.post.id
      ? await apiPut({ authToken, endpoint: `/offices/${office_id}/posts/${submitData.post.id}`, data: submitData })
      : await apiPost({ authToken, endpoint: `/offices/${office_id}/posts`, data: submitData });
    !res.error ? history.goBack() : alert(res.error);
  };

  return (
    <div className="uk-flex-1">
      <div className="uk-container uk-container-small">
        <div className="uk-text-center">
          <PageTitle title={post_id ? '投稿を編集' : '新規投稿作成'} />
          <div className="uk-form-horizontal uk-text-left">

            <div>
              <label className="uk-form-label">投稿の種類 <span className="uk-text-danger uk-text-bold">＊</span></label>
              <div className="uk-form-controls">
                <select className="uk-select" value={post.post_type}
                  onChange={e => setPost({ ...post, post_type: e.target.value })}>
                  <option value="default">通常投稿</option>
                  <option value="important">重要なお知らせ</option>
                  <option value="child">子育て情報</option>
                  <option value="event">イベント</option>
                  <option value="emergency">緊急告知・災害情報</option>
                </select>
              </div>
            </div>

            {post.post_type === 'event' && (
              <>
                <div className="uk-margin">
                  <label className="uk-form-label">イベント名 <span className="uk-text-danger uk-text-bold">＊</span></label>
                  <div className="uk-form-controls">
                    <input type="text" className="uk-input" placeholder="イベント名" value={post.title}
                      onChange={e => setPost({ ...post, title: e.target.value })} />
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">開催期間 <span className="uk-text-danger uk-text-bold">＊</span></label>
                  <div className="uk-form-controls">
                    <div className="uk-flex uk-flex-row uk-flex-middle">
                      <label className="uk-margin-right">
                        <input type="radio" name="date-type" className="uk-radio uk-margin-small-right" onChange={() => setDateType('range')} checked={dateType === 'range'} />
                        期間
                      </label>
                      <label className="uk-margin-right">
                        <input type="radio" name="date-type" className="uk-radio uk-margin-small-right" onChange={() => setDateType('single')} checked={dateType === 'single'} />
                        単日
                      </label>
                      <span className="uk-text-meta">＊ 半角数字のみ</span>
                    </div>
                    <div className="uk-flex uk-flex-row uk-flex-between uk-flex-middle uk-margin-small-top">
                      <div>
                        <div className="uk-inline" style={{ marginRight: 4 }}>
                          <span className="uk-form-icon uk-form-icon-flip">年</span>
                          <input
                            type="number"
                            className="uk-input uk-text-right"
                            style={{ width: 120 }}
                            value={startDate.year}
                            onChange={e => setStartDate({ ...startDate, year: e.target.value })} />
                        </div>
                        <div className="uk-inline" style={{ marginRight: 4 }}>
                          <span className="uk-form-icon uk-form-icon-flip">月</span>
                          <input
                            type="number"
                            className="uk-input uk-text-right"
                            style={{ width: 80 }}
                            value={startDate.month}
                            onChange={e => {
                              const num = parseInt(e.target.value);
                              const month = num < 10 ? `0${num}` : `${num}`;
                              setStartDate({ ...startDate, month });
                            }} />
                        </div>
                        <div className="uk-inline">
                          <span className="uk-form-icon uk-form-icon-flip">日</span>
                          <input
                            type="number"
                            className="uk-input uk-text-right"
                            style={{ width: 80 }}
                            value={startDate.day}
                            onChange={e => {
                              const num = parseInt(e.target.value);
                              const day = num < 10 ? `0${num}` : `${num}`;
                              setStartDate({ ...startDate, day });
                            }} />
                        </div>
                      </div>
                      {dateType === 'range' && (
                        <>
                          <span className="uk-margin-small-left uk-margin-small-right uk-text-muted">〜</span>
                          <div>
                            <div className="uk-inline" style={{ marginRight: 4 }}>
                              <span className="uk-form-icon uk-form-icon-flip">年</span>
                              <input
                                type="number"
                                className="uk-input uk-text-right"
                                style={{ width: 120 }}
                                value={endDate.year}
                                onChange={e => setEndDate({ ...endDate, year: e.target.value })} />
                            </div>
                            <div className="uk-inline" style={{ marginRight: 4 }}>
                              <span className="uk-form-icon uk-form-icon-flip">月</span>
                              <input
                                type="number"
                                className="uk-input uk-text-right"
                                style={{ width: 80 }} 
                                value={endDate.month}
                                onChange={e => {
                                  const num = parseInt(e.target.value);
                                  const month = num < 10 ? `0${num}` : `${num}`;
                                  setEndDate({ ...endDate, month });
                                }} />
                            </div>
                            <div className="uk-inline">
                              <span className="uk-form-icon uk-form-icon-flip">日</span>
                              <input
                                type="number"
                                className="uk-input uk-text-right"
                                style={{ width: 80 }} 
                                value={endDate.day}
                                onChange={e => {
                                  const num = parseInt(e.target.value);
                                  const day = num < 10 ? `0${num}` : `${num}`;
                                  setEndDate({ ...endDate, day });
                                }} />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="uk-margin">
                  <label className="uk-form-label">開催場所 <span className="uk-text-danger uk-text-bold">＊</span></label>
                  <div className="uk-form-controls">
                    <input type="text" className="uk-input" placeholder="開催場所を入力してください" value={post.venue} onChange={e => setPost({ ...post, venue: e.target.value })} />
                  </div>
                </div>
              </>
            )}

            {['important', 'emergency', 'child'].includes(post.post_type) && (
              <div className="uk-margin">
                <label className="uk-form-label">タイトル <span className="uk-text-danger uk-text-bold">＊</span></label>
                <div className="uk-form-controls">
                  <input type="text" className="uk-input" placeholder="タイトル" value={post.title}
                    onChange={e => setPost({ ...post, title: e.target.value })} />
                </div>
              </div>
            )}

            <div className="uk-margin">
              <label className="uk-form-label">本文 <span className="uk-text-danger uk-text-bold">＊</span></label>
              <div className="uk-form-controls">
                <textarea className="uk-textarea uk-height-small" placeholder="投稿内容" value={post.body} onChange={e => setPost({ ...post, body: e.target.value })} />
              </div>
            </div>

            <div className="uk-margin">
              <label className="uk-form-label">URL</label>
              <div className="uk-form-controls">
                <input type="text" value={post.url} className="uk-input" placeholder="https://" onChange={e => setPost({ ...post, url: e.target.value })} />
              </div>
            </div>

            <div className="uk-margin-top">
              <label className="uk-form-label">画像（3枚まで）</label>
              <div className="uk-form-controls">
                <div className="uk-grid-small uk-child-width-1-3@s" data-uk-grid>
                  
                  {post.images_attributes.filter(img => !img._destroy).map((image, i) => {
                    const url = typeof(image.url) === 'object' ? image.url.url : image.url;
                    return (
                      <div key={`image-${i}`}>
                        <div className="uk-flex-1 uk-position-relative uk-flex uk-flex-center uk-flex-middle uk-background-secondary uk-height-1-1 uk-background-secondary">
                          <img src={url} alt="" className="uk-width-1-1" />
                          <button className="uk-icon-button uk-position-absolute" uk-icon="trash" style={{ top: 8, right: 8 }}
                            onClick={e => { if (window.confirm("画像を削除してもよろしいですか？")) removeImage(image, i); }} />
                        </div>
                      </div>
                    )
                  })}

                  <div>
                    <div
                      data-uk-form-custom
                      className="uk-placeholder uk-flex-1 uk-margin-remove-top uk-margin-remove-bottom uk-position-relative uk-width-1-1 uk-padding-remove-bottom"
                      style={{ paddingTop: '100%' }}
                      onChange={e => {
                        if (!!e.target.files.length) {
                          setTmpImage(e.target.files[0]);
                          e.target.value = null;
                        }
                      }}>
                      <div
                        className="uk-flex uk-flex-center uk-flex-middle uk-width-1-1 uk-height-1-1 uk-position-absolute"
                        style={{ top: 0, left: 0 }}>
                        <input type="file" />
                        <span uk-icon="icon: plus" className="uk-text-muted" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="uk-margin">
              <label className="uk-form-label">パーソナライズ</label>
              <div className="uk-form-controls">
                <p className="uk-text-small">＊ 個別で設定しない場合、全ユーザー向け・新着順での表示となります。ユーザー側でパーソナライズされた記事がある場合、ユーザーによっては表示順が下がることもあります。（重要・緊急・災害情報を除く）</p>
                <ul data-uk-accordion>
                  <li>
                    <a className="uk-accordion-title uk-button uk-button-default" href="#" style={{ padding: 8, fontSize: 14 }}>パーソナライズを個別で設定する</a>
                    <div className="uk-accordion-content uk-background-muted uk-padding-small">

                      <div className="uk-flex uk-flex-row">
                        <div className="uk-flex-none uk-text-small" style={{ width: 80 }}>年齢</div>
                        <div>
                          <label className="uk-text-small">年齢層</label>
                          <div className="uk-flex-1 uk-flex uk-flex-row uk-flex-wrap uk-margin-small-bottom">
                            {allAgeGroups.map((ageGroup, i) => (
                              <div
                                key={`${ageGroup}-${i}`}
                                className={`personalize-pill uk-margin-small-right uk-margin-small-top ${tags['ageGroups'].includes(ageGroup) ? 'active' : ''}`}
                                onClick={() => updateTag('ageGroups', ageGroup)}>
                                <p className="uk-margin-remove">{ageGroup}</p>
                              </div>
                            ))}
                          </div>
                          <label className="uk-text-small">こども年齢</label>
                          <div className="uk-flex-1 uk-flex uk-flex-row uk-flex-wrap">
                            {allAges.map((age, i) => (
                              <div
                                key={`${age}-${i}`}
                                className={`personalize-pill uk-margin-small-right uk-margin-small-top ${tags['ageGroups'].includes(age) ? 'active' : ''}`}
                                onClick={() => updateTag('ageGroups', age)}>
                                <p className="uk-margin-remove">{age}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="uk-flex uk-flex-row uk-margin-top">
                        <div className="uk-flex-none uk-text-small uk-margin-small-top" style={{ width: 80 }}>性別</div>
                        <div className="uk-flex-1 uk-flex uk-flex-row uk-flex-wrap">
                          {allGenders.map((gender, i) => (
                            <div
                              key={`${gender}-${i}`}
                              className={`personalize-pill uk-margin-small-right uk-margin-small-top ${tags['genders'].includes(gender) ? 'active' : ''}`}
                              onClick={() => updateTag('genders', gender)}>
                              <p className="uk-margin-remove">{gender}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="uk-flex uk-flex-row uk-margin-top">
                        <div className="uk-flex-none uk-text-small uk-margin-small-top" style={{ width: 80 }}>関心</div>
                        <div className="uk-flex-1 uk-flex uk-flex-row uk-flex-wrap uk-margin-small-bottom">
                          {allInterests.map((interest, i) => (
                            <div
                              key={`${interest}-${i}`}
                              className={`personalize-pill uk-margin-small-right uk-margin-small-top ${tags['interests'].includes(interest) ? 'active' : ''}`}
                              onClick={() => updateTag('interests', interest)}>
                              <p className="uk-margin-remove">{interest}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="uk-flex uk-flex-row uk-margin-top">
                        <div className="uk-flex-none uk-text-small uk-margin-small-top" style={{ width: 80 }}>地域</div>
                        <div className="uk-flex-1 uk-margin-small-bottom">
                          <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-small-bottom">
                            <label className="uk-margin-right">
                              <input type="radio" className="uk-radio uk-margin-small-right" onChange={e => setLocations({...locations, type: 'allLocations'})} checked={locations.type === 'allLocations'} />
                              他の地域
                            </label>
                            <label className="uk-margin-right">
                              <input type="radio" className="uk-radio uk-margin-small-right" onChange={e => setLocations({...locations, type: 'neighborhoods'})} checked={locations.type === 'neighborhoods'} />
                              町名など
                            </label>
                            <label>
                              <input type="radio" className="uk-radio uk-margin-small-right" onChange={e => setLocations({...locations, type: 'schoolDistricts'})} checked={locations.type === 'schoolDistricts'} />
                              小学校区
                            </label>
                          </div>
                          <div className="uk-flex uk-flex-row">
                            {locations.type === 'allLocations' && (
                              <>
                                <select
                                  className="uk-select uk-margin-small-right"
                                  style={{ minWidth: 0 }}
                                  value={locations.prefecture}
                                  onChange={e => setLocations({...locations, prefecture: e.target.value})}>
                                  {Object.keys(allLocations).map((prefecture, i) => (
                                    <option key={`personalize-prefecture-${i}`} value={prefecture}>{prefecture}</option>
                                  ))}
                                </select>
                                <select
                                  className="uk-select uk-margin-small-right"
                                  style={{ minWidth: 0 }}
                                  value={locations.area}
                                  onChange={e => setLocations({...locations, area: e.target.value})}
                                  disabled={!locations.prefecture}>
                                  <option value="">市区町村を選択してください</option>
                                  {!!locations.prefecture && allLocations[locations.prefecture].map((area, i) => (
                                    <option key={`personalize-area-${i}`} value={area}>{area}</option>
                                  ))}
                                </select>
                              </>
                            )}
                            {locations.type === 'neighborhoods' && (
                              <select
                                className="uk-select uk-margin-small-right"
                                value={locations.neighborhood}
                                onChange={e => setLocations({...locations, neighborhood: e.target.value})}>
                                <option value="" disabled>地域・町名を選択してください</option>
                                {locations.neighborhoods.map((hood, i) => (
                                  <option key={`personalize-neighborhood-${i}`} value={hood.neighborhood}>{hood.neighborhood}</option>
                                ))}
                              </select>
                            )}
                            {locations.type === 'schoolDistricts' && (
                              <select
                                className="uk-select uk-margin-small-right"
                                value={locations.school_district}
                                onChange={e => setLocations({...locations, school_district: e.target.value})}>
                                <option value="" disabled>小学校区を選択してください</option>
                                {locations.school_districts.map((district, i) => (
                                  <option key={`personalize-distrcit-${i}`} value={district.school_district}>{district.school_district}</option>
                                ))}
                              </select>
                            )}
                            <button
                              className="uk-button uk-button-secondary uk-flex-none"
                              disabled={locations.type === 'allLocations' && !locations.area}
                              onClick={() => {
                                const value = locations.type === 'allLocations'
                                  ? `${locations.prefecture}${locations.area}`
                                  : locations.type === 'neighborhoods'
                                  ? `${primaryLocation.prefecture}${primaryLocation.area}${locations.neighborhood}`
                                  : `${primaryLocation.prefecture}${primaryLocation.area}${locations.school_district}`;
                                updateTag('areas', value);
                              }}>
                              追加
                            </button>
                          </div>
                          <div className="uk-flex-1 uk-flex uk-flex-row uk-flex-wrap uk-margin-small-bottom">
                            {tags.areas.map((areaTag, i) => (
                              <div key={`area-tag-${i}`} className="personalize-pill uk-flex uk-flex-middle uk-margin-small-right uk-margin-small-top">
                                <p className="uk-margin-remove">{areaTag}</p>
                                <span className="uk-margin-small-left" data-uk-icon="icon: close; ratio: 0.8;" onClick={() => updateTag('areas', areaTag)}></span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="uk-margin-medium">
              <label className="uk-form-label"></label>
              <div className="uk-form-controls">
                <div className="uk-flex">
                  <button
                    className="uk-button uk-button-default uk-margin-small-right uk-button-rounded"
                    disabled={submitting}
                    onClick={() => history.goBack()}>
                    キャンセル
                  </button>
                  <button
                    className="uk-button uk-button-primary uk-button-rounded"
                    disabled={!safeToSubmit() || submitting}
                    onClick={submit}>
                    {post.id ? "変更を保存する" : "投稿する"}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <ImageCropper
        source={tmpImage}
        options={{ unit: '%', width: 80, aspect: 1 / 1 }}
        onClose={() => setTmpImage(null)}
        onComplete={blob => addImage(blob)} />

      {(submitting || loading) && <Loading />}

    </div>
  );
};

const NewOrEditWithContext = withContext(NewOrEdit);
export { NewOrEditWithContext as NewOrEdit };
