import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { withContext } from '../../context';
import logo from '../../assets/logo.svg';

const Header = ({ context }) => {
  const location = useLocation();

  return (
    <div className="uk-width-1-1 gradient uk-padding-small uk-flex-none">
      <div className="uk-container uk-container-small">
        <div className="uk-width-1-1 uk-flex uk-flex-row uk-flex-between uk-flex-middle">
          <img src={logo} style={{ height: 48 }} />
          <div>
            {context.appData.role === 0 && location.pathname !== '/admin' && (
              <Link to="/admin" className="uk-button uk-button-primary uk-button-small uk-margin-small-right uk-button-rounded">
                管理者用メニュー
              </Link>
            )}
            {location.pathname !== '/' && !location.pathname.includes('/admin') && (
              <Link to="/" className="uk-button uk-button-primary uk-button-small uk-margin-small-right uk-button-rounded">
                メインメニューに戻る
              </Link>
            )}
            <button
              onClick={() => context.logout()}
              className="uk-button uk-button-danger uk-button-small uk-button-rounded">ログアウト</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeaderWithContext = withContext(Header);
export { HeaderWithContext as Header };
