import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import { randomString } from '../../utils/utils';
import { Modal } from '../modal/modal';

class ImageCropper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      source: null,
      crop: props.options,
      finalImage: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.source && this.props.source) this.initialLoad();
    if (prevProps.source && !this.props.source) this.setState({ source: null });
    if (this.state.crop.aspect !== this.props.options.aspect) this.setState({ crop: this.props.options });
  }

  initialLoad = () => {
    const reader = new FileReader();
    reader.addEventListener('load', () => this.setState({ source: reader.result }));
    reader.readAsDataURL(this.props.source);
  }

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const random = randomString();
      const croppedImage = await this.getCroppedImg(this.imageRef, crop, `${random}.jpg`);
      this.setState({ finalImage: croppedImage });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ url: this.fileUrl, file: blob });
      }, 'image/jpeg');
    });
  }

  render() {
    const { onClose, onComplete } = this.props;
    const { source, crop, finalImage } = this.state;

    return source ? (
      <Modal show={!!source}>
        <div className="uk-modal-header uk-flex uk-flex-between">
          <h4 className="uk-margin-remove">表示範囲の指定</h4>
          <button className="uk-button-icon" data-uk-icon="close" onClick={onClose} />
        </div>
        <ReactCrop
          src={source}
          crop={crop}
          ruleOfThirds
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange} />
        <div className="uk-modal-footer uk-flex uk-flex-right">
          <button className="uk-button uk-button-default uk-button-rounded uk-margin-small-right" onClick={onClose}>キャンセル</button>
          <button
            className="uk-button uk-button-primary uk-button-rounded"
            onClick={() => {
              this.setState({ source: null });
              onComplete(finalImage);
            }}>
            決定
          </button>
        </div>
      </Modal>
    ) : null;
  }
}

export { ImageCropper };