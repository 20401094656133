import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withContext } from '../../context';
import { apiGet, apiPut } from '../../utils/api';
import { Loading } from '../loading/loading';
import { PageTitle } from '../shared';

const UserInfo = ({ context }) => {
  const [submitting, setSubmitting] = useState(false);
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

  const history = useHistory();
  let mounted = true;

  useEffect(() => {
    fetch();
    return () => mounted = false;
  }, []);

  const fetch = async () => {
    const { authToken, office_id, office_user_id } = context.appData;
    const res = await apiGet({ authToken, endpoint: `/offices/${office_id}/office_users/${office_user_id}` });
    !res.error && mounted
      ? setUser(res)
      : alert(res.error);
  };

  const submittable = () => {
    let result = true;
    if (!user?.username) result = false;
    if (!!password) {
      if (!newPassword) result = false;
      if (!newPasswordConfirmation) result = false;
      if (newPassword !== newPasswordConfirmation) result = false;
    }
    return result;
  };

  const submit = async () => {
    const { authToken, office_id, office_user_id } = context.appData;
    const data = { office_user: user };
    if (!!password) {
      data.password = password;
      data.new_password = newPassword;
    }
    const res = await apiPut({
      authToken, data,
      endpoint: `/offices/${office_id}/office_users/${office_user_id}`,
    });
    if (!res.error) {
      history.goBack();
    } else {
      alert(res.error);
    }
  };

  return (
    <div className="uk-flex-1">
      <div className="uk-container uk-container-small">
        <div className="uk-text-center">
          <PageTitle title="ユーザー情報の変更" />
        </div>

        <div className="uk-form-horizontal uk-text-left">

          <div>
            <label className="uk-form-label"></label>
            <div className="uk-form-controls">
              <h4>ユーザー情報</h4>
            </div>
          </div>
          <div>
            <label className="uk-form-label">メールアドレス <span className="uk-text-danger uk-text-bold">＊</span></label>
            <div className="uk-form-controls">
              <input type="email" className="uk-input" value={user ? user.email : ''} readOnly disabled />
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">ユーザー名 <span className="uk-text-danger uk-text-bold">＊</span></label>
            <div className="uk-form-controls">
              <input type="text" className="uk-input" value={user ? user.username : ''} onChange={e => setUser({ ...user, username: e.target.value })} />
            </div>
          </div>

          <div className="uk-margin-medium-top">
            <label className="uk-form-label"></label>
            <div className="uk-form-controls">
              <h4>パスワードを変更</h4>
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">現在のパスワード</label>
            <div className="uk-form-controls">
              <input type="password" className="uk-input" value={password} onChange={e => setPassword(e.target.value)} placeholder="現在のパスワード" />
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">新しいパスワード</label>
            <div className="uk-form-controls">
              <input type="password" className="uk-input" value={newPassword} onChange={e => setNewPassword(e.target.value)} placeholder="新しいのパスワード" />
            </div>
          </div>
          <div className="uk-margin-top">
            <label className="uk-form-label">新しいパスワードの確認</label>
            <div className="uk-form-controls">
              <input type="password" className="uk-input" value={newPasswordConfirmation} onChange={e => setNewPasswordConfirmation(e.target.value)} placeholder="新しいパスワードの確認" />
            </div>
          </div>

          <div className="uk-margin-medium-top">
            <label className="uk-form-label"></label>
            <div className="uk-form-controls">
              <button
                className="uk-button uk-button-rounded uk-margin-small-right uk-text-bold"
                onClick={() => history.goBack()}>
                キャンセル
              </button>
              <button
                className="uk-button uk-button-primary uk-button-rounded uk-text-bold"
                disabled={!submittable()}
                onClick={submit}>
                変更
              </button>
            </div>
          </div>

        </div>

      </div>
      {(user === null || submitting) && <Loading />}
    </div>
  );
};

const UserInfoWithContext = withContext(UserInfo);
export { UserInfoWithContext as UserInfo };