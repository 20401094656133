import React, { PureComponent } from 'react';
import { withContext } from '../../context';
import { apiGet, apiPut } from '../../utils/api';
import { uploadImage } from '../../utils/images';
import { locations as locationsData } from '../../utils/locations';
import { Modal } from '../modal/modal';
import { PageTitle } from '../shared';
import { ImageCropper } from '../imageCropper/imageCropper';
import { Loading } from '../loading/loading';

class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      office: null,
      locations: [],
      images: [],
      deleteImages: [],
      tmpImage: null,
      tmpImageTarget: null,
      banners: [],
      newBanner: null,
      locationsText: '',
      districtsText: '',
      bannerModal: false,
      submitting: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { appData } = this.props.context;
    const { authToken, office_id } = appData;
    const res = await apiGet({ authToken, endpoint: `/offices/${office_id}` });
    !res.error
      ? this.setState({ ...this.state, ...res })
      : alert(res.error);
  }

  submit = async () => {
    this.setState({ submitting: true });
    const authToken = this.props.context.appData.authToken;
    const { office, locations, images, deleteImages, banners } = this.state;
    
    const data = { ...office };
    data.locations_attributes = locations;
    data.images_attributes = [];
    data.banners_attributes = [];

    if (!!office.avatar_url && typeof(office.avatar_url) === 'object') {
      const avatarUrl = await uploadImage({ authToken, file: office.avatar_url.file });
      !!avatarUrl ? data.avatar_url = avatarUrl : data.avatar_url = null;
    }

    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (!image.id) {
        const url = await uploadImage({ authToken, file: image.url.file });
        if (url) data.images_attributes.push({ url });
      } else {
        data.images_attributes.push(image);
      }
    }

    for (let n = 0; n < banners.length; n++) {
      const banner = { ...banners[n] };
      if (!!banner.image_url && typeof(banner.image_url) === 'object') {
        const url = await uploadImage({ authToken, file: banner.image_url.file });
        if (url) {
          banner.image_url = url;
          data.banners_attributes.push(banner);
        }
      } else {
        data.banners_attributes.push(banner);
      }
    }

    const res = await apiPut({ authToken, endpoint: `/offices/${office.id}`, data: { office: data, delete_images: deleteImages } });
    if (!res.error) this.props.history.goBack();
  }

  addAvatarImage = imgObj => {
    this.setState({ office: { ...this.state.office, avatar_url: imgObj  }, tmpImage: null, tmpImageTarget: null });
  }

  removeAvatarImage = () => {
    const newOffice = { ...this.state.office };
    const newDeleteImages = [ ...this.state.deleteImages ];
    if (typeof(newOffice.avatar_url) === 'string') newDeleteImages.push(newOffice.avatar_url);
    newOffice.avatar_url = null;
    this.setState({ office: newOffice, deleteImages: newDeleteImages });
  }

  addImage = imgObj => {
    const newImages = [ ...this.state.images ];
    if (newImages.length < 3) newImages.push({ url: imgObj });
    this.setState({ images: newImages, tmpImage: null, tmpImageTarget: null });
  };

  removeImage = (e, position) => {
    e.preventDefault();
    const newImages = [ ...this.state.images ];
    const newDeleteImages = [ ...this.state.deleteImages ];
    const target = newImages[position];
    if (target.id) {
      newDeleteImages.push(target.url);
      target._destroy = true;
    } else {
      newImages.splice(position, 1);
    }
    this.setState({ images: newImages, deleteImages: newDeleteImages });
  };

  addBanner = () => {
    const { office, banners } = this.state;
    const newBanners = [ ...banners ];
    const newBanner = { ...this.state.newBanner };
    if (newBanner.image_url && newBanner.background_color) delete newBanner.image_url;
    newBanner.office_id = office.id;
    newBanners.push(newBanner);
    this.setState({ banners: newBanners, newBanner: null, bannerModal: false, tmpImage: null, tmpImageTarget: null });
  };

  addBannerImage = imgObj => {
    const newBanner = { ...this.state.newBanner };
    newBanner.image_url = imgObj;
    newBanner.background_color = null;
    this.setState({ newBanner, tmpImage: null, tmpImageTarget: null });
  }

  removeBanner = (banner) => {
    const newBanners = [ ...this.state.banners ];
    const newDeleteImages = [ ...this.state.deleteImages ];
    const index = newBanners.findIndex(bn => bn.text === banner.text && !bn._destroy);
    banner.id
      ? newBanners[index]._destroy = true
      : newBanners.splice(index, 1);
    if (banner.image_url && typeof(banner.image_url) === 'string') {
      newDeleteImages.push(banner.image_url);
    }
    this.setState({ banners: newBanners, deleteImages: newDeleteImages });
  };

  addLocations = locationType => {
    const { office, locationsText, districtsText } = this.state;
    const arr = locationType === 'neighborhood' ? locationsText.split('、') : districtsText.split('、');
    const newLocations = arr.filter(l => l.trim() !== '').map(loc => {
      const result = { prefecture: office.prefecture, area: office.area };
      result[locationType] =  loc.trim();
      return result;
    });
    this.setState({ locations: [...this.state.locations, ...newLocations], locationsText: '', districtsText: '' });
  };

  removeLocation = location => {
    const newLocations = [...this.state.locations];
    const index = newLocations.indexOf(location);
    !!newLocations[index].id
      ? newLocations[index]._destroy = true
      : newLocations.splice(index, 1);
    this.setState({ locations: newLocations });
  };

  render() {
    const { office, locations, images, tmpImage, tmpImageTarget, banners, newBanner, locationsText, districtsText, bannerModal, submitting } = this.state;

    const prefectures = Object.keys(locationsData);
    const areas = office ? locationsData[office.prefecture] : [];

    const bannerColors = [
      { background_color: '#AEE1FC', color: '#333333' },
      { background_color: '#FFDBCE', color: '#333333' },
      { background_color: '#FDFBC3', color: '#333333' },
      { background_color: '#FFD800', color: '#333333' },
      { background_color: '#4175FF', color: '#FFFFFF' },
      { background_color: '#05A029', color: '#FFFFFF' },
      { background_color: '#9C14C4', color: '#FFFFFF' },
    ];

    return (
      <div className="uk-flex-1">
        <div className="uk-container uk-container-small">
          <div className="uk-text-center">
            <PageTitle title="基本設定" />
            {office && (
              <div className="uk-text-left">
                <div className="uk-form-horizontal uk-margin-large-bottom">

                  <div>
                    <label className="uk-form-label">自治体名 <span className="uk-text-danger uk-text-bold">＊</span></label>
                    <div className="uk-form-controls">
                      <input
                        name="name"
                        type="text"
                        className="uk-input"
                        placeholder="自治体名"
                        value={office.name}
                        onChange={e => this.setState({ office: { ...office, name: e.target.value }})}
                        required />
                    </div>
                  </div>

                  <div className="uk-margin-top">
                    <label className="uk-form-label">所在地 <span className="uk-text-danger uk-text-bold">＊</span></label>
                    <div className="uk-form-controls">
                      <div className="uk-flex uk-flex-row">
                        <select
                          className="uk-select uk-margin-small-right" defaultValue={office.prefecture} required disabled>
                          {prefectures.map((prefecture, i) => <option key={`${i}-${prefecture}`} value={prefecture}>{prefecture}</option>)}
                        </select>
                        <select
                          name="area" className="uk-select" placeholder="市区町村を選択してください" defaultValue={office.area} required disabled>
                          {areas.map((area, i) => <option key={`${i}-${area}`} value={area}>{area}</option>)}
                        </select>
                      </div>
                      <input
                        name="address"
                        type="text"
                        className="uk-input uk-margin-top"
                        placeholder="町名・番地など"
                        value={office.address || ''}
                        onChange={e => this.setState({ office: { ...office, address: e.target.value }})}
                        required />
                    </div>
                  </div>

                  <div className="uk-margin-top">
                    <label className="uk-form-label">電話番号 <span className="uk-text-danger uk-text-bold">＊</span></label>
                    <div className="uk-form-controls">
                      <input
                        name="phone_number"
                        type="text"
                        className="uk-input"
                        placeholder="電話番号"
                        value={office.phone_number}
                        onChange={e => this.setState({ office: { ...office, phone_number: e.target.value } })}
                        required />
                    </div>
                  </div>

                  <div className="uk-margin-top">
                    <label className="uk-form-label">公式WEBサイトURL</label>
                    <div className="uk-form-controls">
                      <input
                        name="url"
                        type="text"
                        className="uk-input"
                        placeholder="https://"
                        value={office.url || ''}
                        onChange={e => this.setState({ office: { ...office, url: e.target.value } })} />
                    </div>
                  </div>

                  <div className="uk-margin-top">
                    <label className="uk-form-label"></label>
                    <div className="uk-form-controls"><hr className="uk-margin-medium-top" /></div>
                  </div>

                  <div className="uk-margin-top">
                    <label className="uk-form-label">プロフィール画像</label>
                    <div className="uk-form-controls">
                      {office.avatar_url ? (
                        <div className="uk-position-relative" style={{ width: 128 }}>
                          <img
                            src={typeof(office.avatar_url) === 'string' ? office.avatar_url : office.avatar_url.url}
                            style={{ width: 128, height: 128, borderRadius: 64 }} />
                          <button
                            onClick={e => {
                              if (window.confirm("画像を削除してもよろしいですか？")) this.removeAvatarImage();
                            }}
                            className="uk-icon-button uk-position-absolute"
                            uk-icon="trash"
                            style={{ top: 8, right: 8 }} />
                        </div>
                      ) : (
                        <div
                          data-uk-form-custom
                          onChange={e => {
                            if (e.target.files && e.target.files.length > 0) {
                              this.setState({ tmpImage: e.target.files[0], tmpImageTarget: 'avatar' });
                            }
                          }}
                          className="uk-placeholder uk-margin-remove-top uk-margin-remove-bottom uk-flex uk-flex-center uk-flex-middle"
                          style={{ width: 128, height: 128 }}>
                          <input type="file" />
                          <span uk-icon="icon: plus" className="uk-text-muted"></span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="uk-margin-medium-top">
                    <label className="uk-form-label">トップページ画像（3枚まで）</label>
                    <div className="uk-form-controls">
                      <div className="uk-grid-small uk-child-width-1-3@s" data-uk-grid>
                        {images.map((image, index) => !image._destroy ? (
                          <div key={`image-${index}`}>
                            <div className="uk-flex-1 uk-position-relative uk-flex uk-flex-center uk-flex-middle uk-background-secondary">
                              <img
                                src={typeof(image.url) === 'string' ? image.url : image.url.url} alt=""
                                className="uk-width-1-1" />
                              <button
                                onClick={e => {
                                  if (window.confirm("画像を削除してもよろしいですか？")) this.removeImage(e, index);
                                }}
                                className="uk-icon-button uk-position-absolute"
                                uk-icon="trash"
                                style={{ top: 8, right: 8 }} />
                            </div>
                          </div>
                        ) : null)}
                        {images.length < 3 && (
                          <div>
                            <div
                              data-uk-form-custom
                              onChange={e => {
                                if (e.target.files && e.target.files.length > 0) this.setState({ tmpImage: e.target.files[0], tmpImageTarget: 'image' });
                              }}
                              className="uk-placeholder uk-width-1-1 uk-margin-remove-top uk-margin-remove-bottom uk-position-relative uk-padding-remove-bottom"
                              style={{ paddingTop: `${(9/16) * 100}%` }}>
                              <div className="uk-position-absolute uk-width-1-1 uk-height-1-1 uk-flex uk-flex-row uk-flex-center uk-flex-middle" style={{ top: 0, left: 0 }}>
                                <input type="file" />
                                <span uk-icon="icon: plus" className="uk-text-muted"></span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="uk-margin-top">
                    <label className="uk-form-label"></label>
                    <div className="uk-form-controls"><hr className="uk-margin-medium-top" /></div>
                  </div>

                  <div className="uk-margin-medium-top">
                    <label className="uk-form-label">バナーリンク</label>
                    <div className="uk-form-controls">
                      <div className="uk-grid-small uk-child-width-1-3" data-uk-grid>
                        {banners.filter(bn => !bn._destroy).map((banner, i) => (
                          <div key={`office-banner-${i}`}>
                            <div
                              className="uk-flex uk-flex-center uk-flex-middle uk-position-relative"
                              style={
                                banner.background_color
                                  ? { backgroundColor: banner.background_color, color: banner.color, paddingTop: `${(9 / 16) * 100}%`  }
                                  : { backgroundImage: `url(${typeof(banner.image_url) === 'string' ? banner.image_url : banner.image_url.url})`, color: banner.color, paddingTop: `${(9 / 16) * 100}%`, backgroundSize: 'cover' }
                              }>
                              <div className="uk-position-absolute uk-height-1-1 uk-width-1-1 uk-flex uk-flex-center uk-flex-middle" style={{ top: 0, left: 0, paddingLeft: 8, paddingRight: 8 }}>
                                <p className="uk-text-bold uk-text-small uk-margin-remove">{banner.text}</p>
                                <div className="uk-position-absolute" style={{ top: 8, right: 8 }}>
                                  <a href={banner.url} className="uk-icon-button uk-margin-small-right" data-uk-icon="link" target="_blank" />
                                  <button
                                    className="uk-icon-button"
                                    data-uk-icon="trash"
                                    onClick={() => {
                                      if (window.confirm("バナーを削除してよろしいですか？")) this.removeBanner(banner);
                                    }} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div>
                          <button
                            className="uk-button uk-placeholder uk-margin-remove-top uk-margin-remove-bottom uk-width-1-1 uk-position-relative uk-padding-remove-bottom"
                            style={{ paddingTop: `${(9 / 16) * 100}%` }}
                            onClick={() => this.setState({ bannerModal: true })}>
                            <div className="uk-position-absolute uk-height-1-1 uk-width-1-1 uk-flex uk-flex-center uk-flex-middle" style={{ top: 0, left: 0 }}>
                              <span uk-icon="icon: plus" className="uk-text-muted"></span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="uk-margin-top">
                    <label className="uk-form-label"></label>
                    <div className="uk-form-controls"><hr className="uk-margin-medium-top" /></div>
                  </div>

                  <div className="uk-margin-medium-top">
                    <label className="uk-form-label">町名など</label>
                    <div className="uk-form-controls">
                      <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-bottom">
                        <input
                          type="text"
                          value={locationsText}
                          onChange={e => this.setState({ locationsText: e.target.value })}
                          className="uk-input uk-margin-small-right uk-flex-1"
                          placeholder="「、」で町名を区切って入力（例： A町、B町、C町）" />
                        <button
                          className="uk-button uk-button-secondary uk-flex-none"
                          onClick={() => this.addLocations('neighborhood')}>
                          追加
                        </button>
                      </div>
                      <div className="uk-flex uk-flex-wrap">
                        {locations.filter(loc => !!loc.neighborhood && !loc._destroy).map((location, i) => (
                          <div
                            key={`neighborhood-${i}`}
                            className="personalize-pill uk-margin-small-bottom uk-margin-small-right">
                            <span className="uk-margin-small-right">{location.neighborhood}</span>
                            <span
                              data-uk-icon="icon: close; ratio: 0.8;"
                              style={{ verticalAlign: 'text-bottom' }}
                              onClick={() => this.removeLocation(location)}></span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="uk-margin-top">
                    <label className="uk-form-label">小学校区</label>
                    <div className="uk-form-controls">
                      <div className="uk-flex uk-flex-row uk-flex-middle uk-margin-bottom">
                        <input
                          type="text"
                          value={districtsText}
                          onChange={e => this.setState({ districtsText: e.target.value })}
                          className="uk-input uk-margin-small-right uk-flex-1"
                          placeholder="「、」で町名を区切って入力（例： A町、B町、C町）" />
                        <button
                          className="uk-button uk-button-secondary uk-flex-none"
                          onClick={() => this.addLocations('school_district')}>
                          追加
                        </button>
                      </div>
                      <div className="uk-flex uk-flex-wrap">
                        {locations.filter(loc => !!loc.school_district && !loc._destroy).map((location, i) => (
                          <div
                            key={`school-district-${i}`}
                            className="personalize-pill uk-margin-small-bottom uk-margin-small-right">
                            <span className="uk-margin-small-right">{location.school_district}</span>
                            <span
                              data-uk-icon="icon: close; ratio: 0.8;"
                              style={{ verticalAlign: 'text-bottom' }}
                              onClick={() => this.removeLocation(location)}></span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="uk-margin-large-top">
                    <label className="uk-form-label"></label>
                    <div className="uk-form-controls">
                      <button className="uk-button uk-button-default uk-button-rounded uk-width-small uk-margin-small-right" onClick={() => this.props.history.goBack()}>
                        キャンセル
                      </button>
                      <button
                        className="uk-button uk-button-primary uk-button-rounded uk-width-small uk-text-bold"
                        onClick={this.submit}>
                        保存
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            )}
          </div>
        </div>

        <Modal show={bannerModal}>
          <div className="uk-modal-header uk-flex uk-flex-between">
            <h4 className="uk-margin-remove">新規バナーリンク作成</h4>
            <button className="uk-button-icon" data-uk-icon="close" onClick={() => this.setState({ bannerModal: false })} />
          </div>
          <div className="uk-modal-body uk-flex-1">
            <div>
              <label className="uk-form-label">リンクテキスト <span className="uk-text-danger">＊</span></label>
              <div className="uk-margin-xsmall-top">
                <input
                  type="text" className="uk-input" placeholder="テキスト（最大30文字）"
                  value={newBanner?.text} onChange={e => this.setState({ newBanner: { ...newBanner, text: e.target.value }})} />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">URL <span className="uk-text-danger">＊</span></label>
              <div className="uk-margin-xsmall-top">
                <input
                  type="text" className="uk-input" placeholder="https://" value={newBanner?.url}
                  onChange={e => this.setState({ newBanner: { ...newBanner, url: e.target.value }})} />
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">スタイル <span className="uk-text-danger">＊</span></label>
              <div className="uk-margin-xsmall-top">
                <div className="uk-grid-small uk-child-width-1-4@s" data-uk-grid>
                  {bannerColors.map((styles, i) => (
                    <div key={`${styles.background_color}-${styles.color}`}>
                      <div className="uk-position-relative">
                        <button
                          onClick={() => this.setState({ newBanner: { ...newBanner, background_color: styles.background_color, color: styles.color } })}
                          className="uk-padding-small uk-text-small banner-style-button uk-text-bold"
                          style={{ backgroundColor: styles.background_color, color: styles.color, height: 80 }}>
                          サンプル
                        </button>
                        {newBanner?.background_color === styles.background_color && (
                          <span className="uk-label uk-background-secondary uk-position-absolute" style={{ top: 0, right: 0 }}>選択中</span>
                        )}
                      </div>
                    </div>
                  ))}
                  {newBanner?.image_url ? (
                    <div>
                      <div
                        className="uk-width-1-1 uk-height-1-1 uk-background-secondary uk-flex uk-flex-middle uk-position-relative"
                        onClick={() => {
                          const nb = { ...newBanner };
                          delete nb.background_color;
                          this.setState({ newBanner: nb });
                        }}>
                        <img
                          src={typeof(newBanner.image_url) === 'string' ? newBanner.image_url : newBanner.image_url.url}
                          style={{ backgroundSize: 'cover' }} />
                        <div className="uk-position-absolute uk-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
                          <span
                            className="uk-text-small uk-text-bold"
                            style={{ color: newBanner.background_color ? '#333333' : newBanner.color }}>
                            テキスト
                          </span>
                        </div>
                        {!newBanner.background_color && (
                          <span className="uk-label uk-background-secondary uk-position-absolute" style={{ top: 0, right: 0 }}>選択中</span>
                        )}
                        <div className="uk-position-absolute uk-flex" style={{ bottom: 8, right: 8 }}>
                          <button
                            className="banner-image-text-button white uk-margin-small-right"
                            onClick={e => {
                              e.stopPropagation();
                              this.setState({ newBanner: { ...newBanner, color: '#FFFFFF' } });
                            }} />
                          <button
                            className="banner-image-text-button black"
                            onClick={e => {
                              e.stopPropagation();
                              this.setState({ newBanner: { ...newBanner, color: '#333333' } });
                            }} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      data-uk-form-custom
                      onChange={e => {
                        const target = e.target.files[0];
                        this.setState({ tmpImage: target, tmpImageTarget: 'banner' });
                      }}>
                      <input type="file" />
                      <button
                        className="uk-padding-small uk-text-small uk-position-relative uk-placeholder uk-margin-remove uk-height-1-1 uk-width-1-1 uk-flex uk-flex-center uk-flex-middle">
                        画像を選択
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="uk-modal-footer uk-flex uk-flex-right">
            <button className="uk-button uk-button-default uk-button-rounded uk-margin-small-right" onClick={() => this.setState({ bannerModal: false })}>キャンセル</button>
            <button
              className="uk-button uk-button-primary uk-button-rounded"
              disabled={!newBanner?.text || !newBanner?.url || (!newBanner?.background_color && !newBanner?.image_url)}
              onClick={this.addBanner}>
              バナーリンクを追加
            </button>
          </div>
        </Modal>

        <ImageCropper
          source={tmpImage}
          options={{ unit: '%', width: 80, aspect: tmpImageTarget === 'avatar' ? (1 / 1) : (16 / 9) }}
          onClose={() => this.setState({ tmpImage: null, tmpImageTarget: null })}
          onComplete={blob => {
            switch (tmpImageTarget) {
              case 'image':
                this.addImage(blob);
                break;
              case 'banner':
                this.addBannerImage(blob);
                break;
              case 'avatar':
                this.addAvatarImage(blob);
                break;
            }
          }} />

        {submitting && <Loading />}

      </div>
    );
  }
}

const SettingsWithContext = withContext(Settings);
export { SettingsWithContext as Settings };
