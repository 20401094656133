export const allAgeGroups = [
  '10歳未満',
  '10代',
  '20代',
  '30代',
  '40代',
  '50代',
  '60代',
  '70代',
  '80代',
  '90歳以上',
];

export const allAges = [
  '妊娠中',
  '0歳',
  '1歳',
  '2歳',
  '3歳',
  '4歳',
  '5歳',
  '6歳',
  '7歳',
  '8歳',
  '9歳',
  '10歳',
  '11歳',
  '12歳',
  '13歳',
  '14歳',
  '15歳',
  '16歳',
  '17歳',
  '18歳',
  '19歳',
];

export const allGenders = [
  '男性',
  '女性',
  'その他',
];

export const allInterests = [
  'イベント',
  '健康・福祉',
  '子育て',
  '教育',
  '歴史',
  '暮らし',
  '市政',
  'ペット',
  '環境',
  '安全・安心',
  'まちづくり',
  '相談',
  '人権',
  '産業・仕事',
  '文化・交流',
  'にぎわい',
  '図書館',
  'スポーツ',
  '税・保険・年金',
  'ランチ',
  'グルメ',
  'レジャー',
  '移住者',
  '外国人',
  '旅行',
  '自然',
  'ボランティア',
  '買い物',
  'お得',
  '習い事',
];

export const trashTypes = [
  '可燃ごみ',
  '瓶・缶・ペットボトル',
  '紙類・古着',
  '容器包装プラ',
];

export const trashDayMap = {
  0: '日',
  1: '月',
  2: '火',
  3: '水',
  4: '木',
  5: '金',
  6: '土',
};

export const trashWeekMap = arr => {
  let result = '';
  const categories = { '第1': [], '第2': [], '第3': [], '第4': [], '第5': [] };
  arr.sort().forEach(day => {
    const split = day.split('/');
    categories[`第${split[0]}`].push(trashDayMap[split[1]]);
  });
  const strs = Object.keys(categories).forEach(week => categories[week].length > 0 ? result += `${week}${categories[week].join('')}, ` : null);
  return result;
};
