import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ hidden = false }) => {
  return !hidden ? (
    <div className="uk-flex-none uk-background-secondary uk-light uk-padding-small">
      <div className="uk-container uk-container-small">
        <div className="uk-flex uk-flex-between uk-flex-middle">
          <p className="uk-text-small uk-margin-remove">&copy; 2020 - 2021 Chiikimori Co., Ltd.</p>
          <p className="uk-margin-remove uk-text-small">
            <Link to="/terms_of_use">利用規約</Link>
            <span> ・ </span>
            <Link to="/privacy_policy">プライバシーポリシー</Link>
          </p>
        </div>
      </div>
    </div>
  ) : null;
};

export { Footer };
