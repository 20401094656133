import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withContext } from '../../context';
import { apiGet } from '../../utils/api';
import { Loading } from '../loading/loading';

const Admin = ({ context }) => {
  const { authToken, role } = context.appData;
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState({});
  const [offices, setOffices] = useState([]);
  const [reportedPosts, setReportedPosts] = useState([]);

  useEffect(() => {
    if (role !== 0) history.replace('/');
    fetch();
  }, []);

  const fetch = async () => {
    const res = await apiGet({ authToken, endpoint: '/offices' });
    if (!res.error) {
      const { offices, reported_posts, ...metricsData } = res;
      setOffices(offices);
      setReportedPosts(reported_posts);
      setMetrics(metricsData);
    }
    setLoading(false);
  };

  return (
    <div className="uk-flex-1">
      <div className="uk-container uk-container-small">
        <div className="uk-margin-medium-top uk-margin-bottom">
          <h4 className="uk-margin-remove uk-text-bold">メトリックス</h4>
        </div>
        <h5 className="uk-heading-line"><span className="uk-text-bold">概要</span></h5>
        <div className="uk-child-width-1-5 uk-margin-medium-bottom" data-uk-grid>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.users_count}</p>
            <p className="uk-margin-remove uk-text-meta">ユーザー数</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.monthly_active_users}</p>
            <p className="uk-margin-remove uk-text-meta">MAU(過去1ヶ月)</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.businesses_count}</p>
            <p className="uk-margin-remove uk-text-meta">組織数</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.offices_count}</p>
            <p className="uk-margin-remove uk-text-meta">自治体数</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.posts_count}</p>
            <p className="uk-margin-remove uk-text-meta">投稿総数</p>
          </div>
        </div>

        <h5 className="uk-heading-line"><span className="uk-text-bold">年齢層</span></h5>
        <div className="uk-child-width-1-6 uk-margin-medium-bottom" data-uk-grid>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.youth}</p>
            <p className="uk-margin-remove uk-text-meta">10歳未満</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.teen}</p>
            <p className="uk-margin-remove uk-text-meta">10代</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.twenties}</p>
            <p className="uk-margin-remove uk-text-meta">20代</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.thirties}</p>
            <p className="uk-margin-remove uk-text-meta">30代</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.forties}</p>
            <p className="uk-margin-remove uk-text-meta">40代</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.fifties}</p>
            <p className="uk-margin-remove uk-text-meta">50代</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.sixties}</p>
            <p className="uk-margin-remove uk-text-meta">60代</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.seventies}</p>
            <p className="uk-margin-remove uk-text-meta">70代</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.eighties}</p>
            <p className="uk-margin-remove uk-text-meta">80代</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.nineties}</p>
            <p className="uk-margin-remove uk-text-meta">90歳以上</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.age_groups?.unknown}</p>
            <p className="uk-margin-remove uk-text-meta">年齢不明</p>
          </div>
        </div>

        <h5 className="uk-heading-line"><span className="uk-text-bold">性別</span></h5>
        <div className="uk-child-width-1-6 uk-margin-medium-bottom" data-uk-grid>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.genders?.male}</p>
            <p className="uk-margin-remove uk-text-meta">男性</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.genders?.female}</p>
            <p className="uk-margin-remove uk-text-meta">女性</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.genders?.other}</p>
            <p className="uk-margin-remove uk-text-meta">その他</p>
          </div>
          <div className="uk-text-center">
            <p className="uk-margin-remove uk-h3">{metrics?.genders?.unknown}</p>
            <p className="uk-margin-remove uk-text-meta">性別不明</p>
          </div>
        </div>

        <div className="uk-margin-bottom uk-flex uk-flex-between uk-flex-middle">
          <h4 className="uk-margin-remove uk-text-bold">アカウント一覧</h4>
          <Link to="/admin/new" className="uk-button uk-button-small uk-button-primary uk-button-rounded">
            新規アカウント作成
          </Link>
        </div>
        <table className="uk-table uk-table-striped uk-table-small uk-text-small uk-table-divider">
          <thead>
            <tr>
              <th>ID</th>
              <th>自治体名</th>
              <th>都道府県</th>
              <th>市区町村</th>
              <th className="uk-text-right">ユーザー数</th>
              <th className="uk-text-right">投稿数</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {offices.map((office, i) => (
              <tr key={`office-${i}-${office.id}`}>
                <td className="uk-text-right uk-table-shrink">{office.id}</td>
                <td>{office.name}</td>
                <td>{office.prefecture}</td>
                <td>{office.area}</td>
                <td className="uk-text-right">{office.users_count}</td>
                <td className="uk-text-right">{office.posts_count}</td>
                <td className="uk-text-right">
                  <button
                    className="uk-button uk-button-small uk-button-link"
                    onClick={() => context.updateAppData({ ...context.appData, office_id: office.id }, () => history.push('/'))}>
                    詳細
                  </button>
                </td>
              </tr>
            ))}
            {!offices.length && (
              <tr>
                <td colSpan={6} className="uk-text-center">表示できる項目がありません</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="uk-margin-bottom uk-flex uk-flex-between uk-flex-middle uk-margin-medium-top">
          <h4 className="uk-margin-remove uk-text-bold">通報された投稿</h4>
          <Link to="/admin/posts" className="uk-button uk-button-small uk-button-primary uk-button-rounded">
            全ての投稿を表示
          </Link>
        </div>
        <table className="uk-table uk-table-striped uk-table-small uk-text-small uk-table-divider">
          <thead>
            <tr>
              <th>ID</th>
              <th>投稿者</th>
              <th>タイトル</th>
              <th>内容</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reportedPosts.map((post, index) => (
              <tr key={`reported-posts-${index}`}>
                <td>{post.id}</td>
                <td>{post.publisher.name}</td>
                <td>{post.title || "タイトルは設定されていません"}</td>
                <td>{`${post.body.substring(0, 30)}...`}</td>
                <td></td>
              </tr>
            ))}
            {!reportedPosts.length && (
              <tr>
                <td colSpan={5} className="uk-text-center">
                  通報された投稿はありません
                </td>
              </tr>
            )}
          </tbody>
        </table>

      </div>
      {loading && <Loading />}
    </div>
  );
};

const AdminWithContext = withContext(Admin);
export { AdminWithContext as Admin };
