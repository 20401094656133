import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withContext } from '../../context';
import { apiPost } from '../../utils/api';
import logo from '../../assets/logo.svg';
import { Loading } from '../loading/loading';

const Login = ({ context }) => {
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const { email, password } = e.target.elements;
    const res = await apiPost({ endpoint: `/authenticate`, data: { email: email.value, password: password.value } });
    if (!res.error) {
      const route = res.role === 0 ? '/admin' : '/';
      context.onLogin(res, () => history.push(route));
    } else {
      alert(res.error);
      setSubmitting(false);
    }
  };

  return (
    <div className="uk-height-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-middle gradient">
      <div className="uk-text-center uk-margin-large-bottom">
        <p className="uk-text-bold uk-text-secondary">地域情報コミュニティアプリ</p>
        <img src={logo} className="uk-width-small" />
      </div>
      <form className="uk-width-medium" onSubmit={handleSubmit}>
        <div className="uk-inline uk-width-1-1">
          <span className="uk-form-icon" uk-icon="icon: user"></span>
          <input
            className="uk-input"
            type="email"
            name="email"
            placeholder="ユーザーID"
            autoFocus />
        </div>
        <div className="uk-inline uk-width-1-1 uk-margin-small-top">
          <span className="uk-form-icon" uk-icon="icon: lock"></span>
          <input
            className="uk-input"
            type="password"
            name="password"
            placeholder="パスワード" />
        </div>
        <button className="uk-button uk-button-primary uk-button-rounded uk-width-1-1 uk-margin-top">ログイン</button>
      </form>
      <p className="uk-margin-large-top uk-text-small">ユーザーID・パスワードをお忘れの場合、管理者へお問い合わせください。</p>
      {submitting && <Loading />}
    </div>
  );
};

const LoginWithContext = withContext(Login);
export { LoginWithContext as Login };
