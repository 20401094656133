import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withContext } from '../../context';
import { apiDelete, apiGet, apiPost } from '../../utils/api';
import { clippedText, displayDate, postTypes } from '../../utils/utils';
import { Modal } from '../modal/modal';
import { PageTitle } from '../shared';
import { Loading } from '../loading/loading';
import { Paginator } from '../paginator/paginator';

class Posts extends PureComponent {
  constructor(props) {
    super(props);
    this.commentsContainer = null;
    this.state = {
      loading: true,
      posts: [],
      post: null,
      postModal: false,
      postTab: 0,
      comments: [],
      comment: {},
      page: 1,
      total_pages: null,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { authToken, office_id } = this.props.context.appData;
    const { page } = this.state;
    const res = await apiGet({ authToken, endpoint: `/offices/${office_id}/posts?page=${page}` });
    if (!res.error) {
      this.setState({ posts: res.posts, total_pages: res.total_pages, loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  fetchPost = async (postId) => {
    const { authToken, office_id } = this.props.context.appData;
    const res = await apiGet({ authToken, endpoint: `/offices/${office_id}/posts/${postId}` });
    !res.error ? this.setState({ post: res }) : alert(res.error);
  }

  fetchComments = async (postId) => {
    const { authToken, office_id } = this.props.context.appData;
    const res = await apiGet({ authToken, endpoint: `/offices/${office_id}/posts/${postId}/comments` });
    !res.error ? this.setState({ comments: res }) : alert(res.error);
  }

  destroy = async (post) => {
    const { authToken, office_id } = this.props.context.appData;
    const res = await apiDelete({ authToken, endpoint: `/offices/${office_id}/posts/${post.id}` });
    if (!res.error) {
      const newPosts = [...this.state.posts];
      const index = newPosts.findIndex(p => p.id === post.id);
      newPosts.splice(index, 1);
      this.setState({ posts: newPosts, postModal: false }, () => {
        setTimeout(() => this.setState({ post: null }), 300);
      });
    } else {
      alert(res.error);
    }
  }
  
  postComment = async () => {
    const { authToken, office_id } = this.props.context.appData;
    const { comments, post, comment } = this.state;
    const res = await apiPost({ authToken, endpoint: `/offices/${office_id}/posts/${post.id}/comments`, data: { comment } });
    if (!res.error) {
      this.setState({ comments: [...comments, res], comment: {} }, () => {
        this.commentsContainer.scrollTop = this.commentsContainer.scrollHeight;
      });
    } else {
      alert(res.error);
    }
  }
  
  destroyComment = async (commentId, index) => {
    const { authToken, office_id } = this.props.context.appData;
    const { post } = this.state;
    const res = await apiDelete({ authToken, endpoint: `/offices/${office_id}/posts/${post.id}/comments/${commentId}` });
    if (!res.error) {
      const newComments = [...this.state.comments];
      newComments.splice(index, 1);
      this.setState({ comments: newComments });
    } else {
      alert(res.error);
    }
  }

  render() {
    const { office_id } = this.props.context.appData;
    const { posts, post, postModal, postTab, comments, comment, page, total_pages, loading } = this.state;

    return (
      <div className="uk-flex-1">
        <div className="uk-container uk-container-small">
          <div className="uk-text-center">
            <PageTitle title="投稿一覧" />
            <div className="uk-text-right">
              <Link to="/posts/new" className="uk-button uk-button-small uk-button-primary uk-button-rounded">
                新規投稿を作成
              </Link>
            </div>
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-small uk-text-left">
              <thead>
                <tr>
                  <th className="uk-table-shrink uk-text-nowrap">日時</th>
                  <th className="uk-table-shrink uk-text-nowrap">種類</th>
                  <th className="uk-table-expand">内容</th>
                  <th className="uk-table-shrink uk-text-nowrap uk-text-right" style={{ textTransform: 'none' }}>Wa-i!数</th>
                  <th className="uk-table-shrink uk-text-nowrap uk-text-right">コメント数</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {posts.map((post, i) => (
                  <tr key={`posts-post-${post.id}-${i}`}>
                    <td className="uk-table-shrink uk-text-nowrap">{displayDate(post.created_at)}</td>
                    <td className="uk-table-shrink uk-text-nowrap">{postTypes[post.post_type]}</td>
                    <td>{post.post_type === 'event' ? clippedText(post.title) : clippedText(post.body)}</td>
                    <td className="uk-text-right">{post.wai_ids.length}</td>
                    <td className="uk-text-right">{post.comments_count}</td>
                    <td className="uk-table-shrink uk-text-nowrap">
                      <button
                        className="uk-button uk-button-link"
                        onClick={() => {
                          this.setState({ postModal: true }, () => this.fetchPost(post.id));
                        }}>
                        詳細
                      </button>
                    </td>
                  </tr>
                ))}
                {posts.length === 0 && (
                  <tr>
                    <td colSpan={6} className="uk-text-center">表示できる投稿がまだありません</td>
                  </tr>
                )}
              </tbody>
            </table>
            <Paginator
              currentPage={page}
              totalPages={total_pages}
              onPrev={() => this.setState({ page: page - 1 }, this.fetch)}
              onNext={() => this.setState({ page: page + 1 }, this.fetch)} />
          </div>
        </div>

        <Modal show={postModal}>
          <div className="uk-modal-header uk-flex uk-flex-between" style={{ borderBottom: 'none' }}>
            <h4 className="uk-margin-remove uk-text-bold">投稿の詳細</h4>
            <button
              className="uk-button-icon"
              data-uk-icon="close"
              onClick={() => this.setState({ postModal: false, postTab: 0, comments: [] }, () => setTimeout(() => this.setState({ post: null }), 300))} />
          </div>
          <ul data-uk-tab className="uk-margin-remove-top uk-margin-remove-bottom">
            <li className={postTab === 0 ? 'uk-active' : ''} style={{ marginLeft: 30 }}>
              <a href="#" onClick={() => this.setState({ postTab: 0 })}>詳細</a>
            </li>
            <li className={postTab === 1 ? 'uk-active' : ''}>
              <a href="#" onClick={() => this.setState({ postTab: 1 }, () => this.fetchComments(post.id))}>コメント</a>
            </li>
          </ul>
          {post && postTab === 0 && (
            <>
              <div className="uk-modal-body uk-flex-1">
                <div>
                  <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                    <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>投稿日</span>
                    <span>{displayDate(post.created_at, true)}</span>
                  </div>
                  <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                    <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>投稿者</span>
                    <span>{post.author}</span>
                  </div>
                  <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                    <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>投稿の種類</span>
                    <span>{postTypes[post.post_type]}</span>
                  </div>

                  {post.post_type === 'event' && (
                    <>
                      <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                        <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>イベント名</span>
                        <span>{post.title}</span>
                      </div>
                      <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                        <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>イベント期間</span>
                        <span>
                          {displayDate(new Date(post.start_date).toISOString())}
                          {post.end_date && (<span> 〜 {displayDate(new Date(post.end_date).toISOString())}</span>)}
                        </span>
                      </div>
                    </>
                  )}

                  <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                    <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>本文</span>
                    <span style={{ whiteSpace: 'pre-wrap' }}>{post.body}</span>
                  </div>
                  <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                    <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>URL</span>
                    <span>{post.url ? <a href={post.url} target="_blank">{post.url}</a> :  "URLは設定されていません"}</span>
                  </div>
                  {!!post.images.length && (
                    <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                      <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>画像</span>
                      <div>
                        <div data-uk-grid className="uk-grid-small uk-child-width-1-3">
                          {post.images.map((image, i) => (
                            <div key={`post-image-${i}`}>
                              <img src={image.url} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="uk-flex uk-flex-row uk-flex-top uk-margin-small-bottom">
                    <span className="uk-width-xsmall uk-text-meta uk-flex-none" style={{ marginTop: 1 }}>パーソナライズ</span>
                    <div className="uk-flex uk-flex-row uk-flex-wrap" style={{ paddingTop: 2 }}>
                      {post.tags.map((tag, i) => (
                        <div key={`post-tag-${i}`} className="personalize-pill active uk-margin-small-right uk-margin-small-bottom">
                          {tag.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-modal-footer uk-flex uk-flex-right uk-flex-middle">
                <button
                  className="uk-button uk-button-danger uk-button-rounded uk-margin-small-right"
                  onClick={() => {
                    if (confirm("削除してもよろしいですか？")) this.destroy(post);
                  }}>
                  削除
                </button>
                <Link to={{ pathname: `/posts/${post.id}/edit`, data: post }} className="uk-button uk-button-primary uk-button-rounded">編集</Link>
              </div>
            </>
          )}
          {post && postTab === 1 && (
            <>
              <div className="uk-modal-body uk-flex-1" ref={ref => this.commentsContainer = ref}>
                {comments.map((comment, i) => (
                  <div
                    key={`comments-comment-${i}`}
                    className="uk-flex uk-flex-row uk-flex-top uk-margin-bottom">
                    <div></div>
                    <div className="uk-flex-1">
                      <div className="uk-flex uk-flex-between uk-flex-middle">
                        <p className="uk-margin-remove uk-text-small uk-text-bold">
                          {comment.author.name}
                          <span className="uk-margin-left uk-text-normal">{comment.time_ago}</span>
                        </p>
                        {comment.commentable_type === 'Office' && comment.author.id === office_id && (
                          <button
                            className="uk-button uk-button-small uk-button-link uk-text-danger"
                            onClick={() => {
                              if (confirm("コメントを削除してもよろしいですか？")) this.destroyComment(comment.id, i);
                            }}>
                            削除
                          </button>
                        )}
                      </div>
                      <div className="uk-padding-small uk-background-muted uk-border-rounded" style={{ marginTop: 4 }}>
                        <p className="uk-margin-remove">{comment.body}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {comments.length === 0 && (
                  <p className="uk-text-center uk-text-muted">表示できるコメントがありません</p>
                )}
              </div>
              <div className="uk-modal-footer uk-text-right uk-flex-middle">
                <textarea
                  className="uk-textarea"
                  style={{ resize: 'none' }}
                  placeholder="コメントを入力してください"
                  value={comment.body || ''}
                  autoFocus
                  onChange={e => this.setState({ comment: {...this.state.comment, body: e.target.value} })} />
                <button
                  className="uk-button uk-button-primary uk-button-small uk-button-rounded uk-margin-small-top"
                  disabled={!comment.body}
                  onClick={this.postComment}>
                  コメントする
                </button>
              </div>
            </>
          )}
          {!post && (
            <div className="uk-modal-body uk-flex-1">
              <div className="uk-width-1-1 uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
                <span className="uk-margin-large-top uk-margin-large-bottom" uk-spinner="ratio: 2"></span>
              </div>
            </div>
          )}
        </Modal>

        {loading && <Loading />}

      </div>
    );
  }
}

const PostsWithContext = withContext(Posts);
export { PostsWithContext as Posts };
