const headers = authToken => {
  const config = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (authToken) config['Authorization'] = `Bearer ${authToken}`;
  return new Headers(config);
};

export const apiGet = async ({ endpoint, authToken }) => {
  const res = await fetch(`/web${endpoint}`, {
    method: 'GET',
    headers: headers(authToken),
  });
  if (res.ok) {
    const parsed = await res.json();
    return parsed;
  } else {
    return { error: '情報の取得に失敗しました。しばらくしてから再度お試しください。' };
  }
};

export const apiPost = async ({ endpoint, data, authToken }) => {
  const res = await fetch(`/web${endpoint}`, {
    method: 'POST',
    headers: headers(authToken),
    body: JSON.stringify(data || {}),
  });
  if (res.ok) {
    const parsed = await res.json();
    return parsed;
  } else {
    return { error: '情報の取得に失敗しました。しばらくしてから再度お試しください。' };
  }
};

export const apiPut = async ({ endpoint, data, authToken }) => {
  const res = await fetch(`/web${endpoint}`, {
    method: 'PUT',
    headers: headers(authToken),
    body: JSON.stringify(data || {}),
  });
  if (res.ok) {
    const parsed = await res.json();
    return parsed;
  } else {
    return { error: '情報の取得に失敗しました。しばらくしてから再度お試しください。' };
  }
};

export const apiDelete = async ({ endpoint, authToken }) => {
  const res = await fetch(`/web${endpoint}`, {
    method: 'DELETE',
    headers: headers(authToken)
  });
  if (res.ok) {
    const parsed = await res.json();
    return parsed;
  } else {
    return { error: '情報の取得に失敗しました。しばらくしてから再度お試しください。' };
  }
};
