import React, { Fragment, useEffect, useState } from 'react';
import { withContext } from '../../context';
import { apiDelete, apiGet, apiPost, apiPut } from '../../utils/api';
import { trashWeekMap, trashTypes, trashDayMap } from '../../utils/options';
import { Modal } from '../modal/modal';
import { PageTitle } from '../shared';

const TrashAreas = ({ context }) => {
  const [trashAreas, setTrashAreas] = useState([]);
  const [trashArea, setTrashArea] = useState(null);
  const [trashSchedule, setTrashSchedule] = useState({ trash_type: '', days: [] });
  const [trashModal, setTrashModal] = useState(false);

  const { authToken, office_id } = context.appData;

  useEffect(() => {
    const fetch = async () => {
      const res = await apiGet({ authToken, endpoint: `/offices/${office_id}/trash_areas` });
      !res.error ? setTrashAreas(res) : alert(res.error);
    };
    fetch();
  }, []);

  const addTrashArea = async () => {
    const { trash_schedules, ...rest } = trashArea;
    const data = { trash_area: rest, trash_schedules };
    const res = await apiPost({ authToken, endpoint: `/offices/${office_id}/trash_areas`, data });
    if (!res.error) {
      setTrashAreas([...trashAreas, res]);
      setTrashModal(false);
      setTimeout(() => setTrashArea({ name: '', trash_schedules: [] }), 300);
    } else {
      alert(res.error);
    }
  };

  const updateTrashArea = async () => {
    const { trash_schedules, ...rest } = trashArea;
    const data = { trash_area: rest, trash_schedules };
    const res = await apiPut({ authToken, endpoint: `/offices/${office_id}/trash_areas/${data.trash_area.id}`, data });
    if (!res.error) {
      const newTrashAreas = [...trashAreas];
      const index = newTrashAreas.findIndex(ta => trashArea.id === ta.id);
      newTrashAreas[index] = res;
      setTrashAreas(newTrashAreas);
      setTrashModal(false);
      setTimeout(() => setTrashArea({ name: '', trash_schedules: [] }), 300);
    } else {
      alert(res.error);
    }
  };

  const deleteTrashArea = async (ta, i) => {
    const res = await apiDelete({ authToken, endpoint: `/offices/${office_id}/trash_areas/${ta.id}` });
    if (!res.error) {
      const newTrashAreas = [...trashAreas];
      newTrashAreas.splice(i, 1);
      setTrashAreas(newTrashAreas);
    } else {
      alert(res.error);
    }
  };

  const handleDayClick = (weekNum, dayNum) => {
    const newTrashSchedule = { ...trashSchedule };
    const index = newTrashSchedule.days.indexOf(`${weekNum}/${dayNum}`);
    index === -1
      ? newTrashSchedule.days.push(`${weekNum}/${dayNum}`)
      : newTrashSchedule.days.splice(index, 1);
    setTrashSchedule(newTrashSchedule);
  };

  return (
    <div className="uk-flex-1">
      <div className="uk-container uk-container-small">
        <div className="uk-text-center">
          <PageTitle title="ごみ出し情報設定" />
          <div className="uk-text-right">
            <button
              className="uk-button uk-button-small uk-button-primary uk-button-rounded"
              onClick={() => {
                setTrashArea({ name: '', trash_schedules: [] });
                setTrashModal(true);
              }}>
              ごみ出し地区を追加
            </button>
          </div>
          <table className="uk-table uk-table-small uk-table-divider uk-text-small uk-text-left">
            <thead>
              <tr>
                <th>地区名</th>
                <th>種類</th>
                <th>スケジュール</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {trashAreas.map((trashArea, i) => {
                const firstSchedule = trashArea.trash_schedules[0];
                return (
                  <Fragment key={`trash-area-${i}`}>
                    <tr>
                      <td rowSpan={trashArea.trash_schedules.length}>{trashArea.name}</td>
                      <td>{firstSchedule.trash_type}</td>
                      <td>{trashWeekMap(firstSchedule.days)}</td>
                      <td className="uk-text-right" rowSpan={trashArea.trash_schedules.length}>
                        <button
                          className="uk-button uk-button-small uk-button-link uk-margin-small-right"
                          onClick={() => {
                            setTrashArea(trashArea);
                            setTrashModal(true);
                          }}>
                          編集
                        </button>
                        <button
                          className="uk-button uk-button-small uk-button-link uk-text-danger"
                          onClick={() => {
                            if (confirm("削除してもよろしいですか？")) deleteTrashArea(trashArea, i);
                          }}>
                          削除
                        </button>
                      </td>
                    </tr>
                    {trashArea.trash_schedules.map((ts, n) => n > 0 ? (
                      <tr key={`trash-area-${i}-trash-schedule-${n}`}>
                        <td>{ts.trash_type}</td>
                        <td>{trashWeekMap(ts.days)}</td>
                      </tr>
                    ) : null)}
                  </Fragment>
                );
              })}
              {trashAreas.length === 0 && (
                <tr>
                  <td colSpan={5} className="uk-text-center">
                    表示できるごみ出し情報がありません
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={trashModal}>
        {trashArea && (
          <>
            <div className="uk-modal-header uk-flex uk-flex-between">
              <h4 className="uk-margin-remove uk-text-bold">ごみ出し情報を{trashArea.id ? '編集' : '追加'}</h4>
              <button
                className="uk-button-icon"
                data-uk-icon="close"
                onClick={() => {
                  setTrashModal(false);
                  setTimeout(() => setTrashArea(null), 300);
                }} />
            </div>
            <div className="uk-modal-body uk-flex-1">
              <div className="uk-flex">
                <label className="uk-form-label uk-width-small uk-flex-none uk-margin-xsmall-top">ごみ出し区域名</label>
                <div className="uk-flex-1">
                  <input
                    type="text"
                    className="uk-input uk-width-1-1"
                    value={trashArea.name}
                    onChange={e => setTrashArea({ ...trashArea, name: e.target.value })}
                    placeholder="ごみ出し区域名" />
                </div>
              </div>

              <div className="uk-flex uk-margin-small-top">
                <label className="uk-form-label uk-width-small uk-flex-none uk-margin-xsmall-top">スケジュール</label>
                <div className="uk-flex-1">
                  <table className="uk-table uk-table-small uk-table-divider uk-table-striped uk-margin-remove uk-text-small">
                    <thead>
                      <tr>
                        <th className="uk-text-nowrap">種類</th>
                        <th>スケジュール</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {trashArea.trash_schedules.map((schedule, i) => (
                        <tr key={`trash-schedules-${i}`}>
                          <td className="uk-table-shrink uk-text-nowrap">{schedule.trash_type}</td>
                          <td>{trashWeekMap(schedule.days)}</td>
                          <td>{schedule.days.map(day => trashDayMap[day]).join(' ')}</td>
                          <td className="uk-text-right uk-table-shrink uk-text-nowrap">
                            <button
                              className="uk-button uk-button-link uk-button-small uk-text-danger"
                              onClick={() => {
                                const newSchedules = [...trashArea.trash_schedules];
                                newSchedules.splice(i, 1);
                                setTrashArea({ ...trashArea, trash_schedules: newSchedules });
                              }}>
                              削除
                            </button>
                          </td>
                        </tr>
                      ))}
                      {trashArea.trash_schedules.length === 0 && (
                        <tr>
                          <td colSpan={4} className="uk-text-center">
                            表示できるスケジュールがありません
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <hr className="uk-margin-top" />

              <div className="uk-flex uk-margin-top">
                <label className="uk-form-label uk-width-small uk-flex-none uk-margin-xsmall-top">スケジュールを追加</label>
                <div className="uk-flex-1">
                  <input
                    type="text"
                    className="uk-input"
                    placeholder="ゴミの種類"
                    onChange={e => setTrashSchedule({ ...trashSchedule, trash_type: e.target.value })} />
                  <table className="uk-margin-small-top uk-width-1-1">
                    <tbody>
                      {[1, 2, 3, 4, 5].map((weekNum, weekIndex) =>  (
                        <tr key={`week-${weekIndex}`}>
                          <td>第{weekNum}週</td>
                          {['日', '月', '火', '水', '木', '金', '土'].map((day, dayIndex) => {
                            const selected = trashSchedule.days.includes(`${weekNum}/${dayIndex}`);
                            return (
                              <td key={`week-${weekNum}-day-${dayIndex}`}>
                                <div
                                  className="uk-flex uk-flex-center uk-flex-middle"
                                  style={{ width: 32, height: 32, borderStyle: selected ? 'solid' : 'dashed', borderColor: selected ? '#3FDECE' :  '#CCCCCC', borderWidth: selected ? 2 : 1, borderRadius: 16 }}
                                  onClick={() => handleDayClick(weekNum, dayIndex)}>
                                  <span style={{ cursor: 'default', color: selected ? '#3FDECE' : '#CCCCCC', fontWeight: selected ? 'bold' : 'normal' }}>{day}</span>
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="uk-text-right uk-margin-small-top">
                    <button
                      className="uk-button uk-button-secondary uk-button-small uk-button-rounded"
                      disabled={!trashSchedule.trash_type || trashSchedule.days.length < 1}
                      onClick={() => {
                        setTrashArea({ ...trashArea, trash_schedules: [...trashArea.trash_schedules, trashSchedule] });
                        setTrashSchedule({ trash_type: '', days: [] });
                      }}>
                      追加
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div className="uk-modal-footer uk-text-right">
              <button
                className="uk-button uk-button-default uk-button-rounded uk-margin-small-right"
                onClick={() => {
                  setTrashModal(false);
                  setTimeout(() => setTrashArea(null), 300);
                }}>
                キャンセル
              </button>
              <button
                className="uk-button uk-button-primary uk-button-rounded"
                disabled={!trashArea.name || !trashArea.trash_schedules.length}
                onClick={trashArea.id ? updateTrashArea : addTrashArea}>
                保存
              </button>
            </div>
          </>
        )}
      </Modal>

    </div>
  );
};

const TrashAreasWithContext = withContext(TrashAreas);
export { TrashAreasWithContext as TrashAreas };
