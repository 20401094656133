import React, { createContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const AppContext = createContext();
const AppConsumer = AppContext.Consumer;

// const cookiesOptions = {
//   path: '/',
//   secure: true,
//   sameSite: true,
//   maxAge: 60 * 720,
// };

function AppProvider({ children }) {
  const storedData = window.sessionStorage.getItem('@appData');
  const initialData = !!storedData ? JSON.parse(storedData) : {};
  const [appData, setAppData] = useState(initialData);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!appData.authToken && !['/', '/terms_of_use', '/privacy_policy', '/overview'].includes(location.pathname)) history.push('/');
  }, [location.pathname]);

  const onLogin = (data, callback) => {
    window.sessionStorage.setItem('@appData', JSON.stringify(data));
    setAppData(data);
    callback();
  };

  const updateAppData = (data, callback) => {
    window.sessionStorage.setItem('@appData', JSON.stringify(data));
    setAppData(data);
    callback();
  };

  const logout = () => {
    window.sessionStorage.removeItem('@appData');
    setAppData({});
    if (location.pathname !== '/') history.push('/');
  };

  return (
    <AppContext.Provider
      value={{ appData, onLogin, logout, updateAppData, hiddenFooter: location.pathname === '/overview' }}>
      {children}
    </AppContext.Provider>
  );
}

function withContext(Cmpt) {
  return props => (
    <AppConsumer>
      {context => <Cmpt {...props} context={context} />}
    </AppConsumer>
  );
}

export { AppContext, AppConsumer, AppProvider, withContext };
