import React from 'react';

const Loading = () => {
  return (
    <div className="loading-overlay uk-flex uk-flex-center uk-flex-middle">
      <div className="uk-text-center">
        <div data-uk-spinner="ratio: 2.0;"></div>
        <p className="uk-text-bold">ロード中</p>
      </div>
    </div>
  );
};

export { Loading };
