import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withContext } from '../../context';
import { apiDelete, apiGet } from '../../utils/api';
import { displayDate } from '../../utils/utils';
import { Loading } from '../loading/loading';
import { Paginator } from '../paginator/paginator';

const AllPosts = ({ context }) => {
  const history = useHistory();
  const { authToken } = context.appData;
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  let mounted = true;
  useEffect(() => {
    if (context.appData.role !== 0) history.replace('/');
    return () => mounted = false;
  }, []);

  useEffect(() => {
    fetch();
  }, [page]);

  const fetch = async () => {
    const res = await apiGet({ authToken, endpoint: `/posts/all_posts?page=${page}` });
    if (!res.error && mounted) {
      setPosts(res.posts);
      setTotalPages(res.total_pages);
      setLoading(false);
    }
  };

  const destroy = async (postId) => {
    setLoading(true);
    const res = await apiDelete({ authToken, endpoint: `/posts/${postId}` });
    if (!res.error) {
      setPosts(posts.filter(post => post.id !== postId));
      setLoading(false);
      alert("削除しました");
    }
  };

  return (
    <div className="uk-flex-1">
      <div className="uk-container uk-container-small">

        <div className="uk-margin-medium-top uk-margin-bottom">
          <h4 className="uk-margin-remove uk-text-bold">全ての投稿</h4>
        </div>

        <table className="uk-table uk-table-striped uk-table-small uk-text-small uk-table-divider">
          <thead>
            <tr>
              <th>投稿日</th>
              <th>投稿者</th>
              <th>内容</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post, index) => (
              <tr key={`all-posts-${index}`}>
                <td className="uk-text-nowrap uk-table-shrink">{displayDate(post.created_at)}</td>
                <td className="uk-text-nowrap uk-table-shrink">{`${post.publisher.name.substring(0, 10)}${post.publisher.name.length > 10 ? '...' : ''}`}</td>
                <td>
                  <p style={{ marginBottom: 4 }}>{post.title || "タイトルは設定されていません"}</p>
                  <p className="uk-margin-remove">{post.body}</p>
                  <div className="uk-child-width-1-4">
                    {post.images.length > 0 && post.images.map((image, imageIndex) => (
                      <div key={`all-post-${index}-image-${imageIndex}`} className="uk-margin-small-top">
                        <img src={image.url} />
                      </div>
                    ))}
                  </div>
                </td>
                <td className="uk-text-nowrap uk-table-shrink">
                  <button className="uk-button uk-button-link uk-text-danger" onClick={() => {
                    if (confirm("本当に不適切な投稿として削除し、投稿者に通知してよろしいですか？")) {
                      destroy(post.id);
                    }
                  }}>削除</button>
                </td>
              </tr>
            ))}
            {!posts.length && (
              <tr>
                <td colSpan={5} className="uk-text-center">
                  通報された投稿はありません
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="uk-margin-large-bottom">
          <Paginator
            currentPage={page}
            totalPages={totalPages}
            onPrev={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)} />
        </div>

      </div>
      {loading && <Loading />}
    </div>
  );
};

const AllPostsWithContext = withContext(AllPosts);
export { AllPostsWithContext as AllPosts };
