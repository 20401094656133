import React, { PureComponent, useEffect, useState } from 'react';
import { withContext } from '../../context';
import { apiDelete, apiGet, apiPost, apiPut } from '../../utils/api';
import { localImageUrl, uploadImage } from '../../utils/images';
import { clippedText, displayDate, randomString } from '../../utils/utils';
import { ImageCropper } from '../imageCropper/imageCropper';
import { Modal } from '../modal/modal';
import { PageTitle } from '../shared';

class ScheduledMessages extends PureComponent {
  constructor(props) {
    super(props);
    this.base = { authToken: props.context.appData.authToken };
    this.blankMessage = { title: '', body: '', url: '', years: '', months: '', images_attributes: [] };
    this.state = {
      messages: [],
      message: null,
      tmpImage: null,
      deleteImages: [],
      modal: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { office_id } = this.props.context.appData;
    const res = await apiGet({ ...this.base, endpoint: `/offices/${office_id}/scheduled_messages` });
    !res.error ? this.setState({ messages: res }) : alert(res.error);
  }

  addImage = blob => {
    const newMessage = { ...this.state.message };
    if (newMessage.images_attributes.length < 3) newMessage.images_attributes.push({ url: blob });
    this.setState({ mesage: newMessage, tmpImage: null });
  }

  removeImage = index => {
    const { deleteImages, message } = this.state;
    const newDeleteImages = [...deleteImages];
    const newMessage = { ...message };
    const image = newMessage.images_attributes[index];
    if (image.id) {
      newDeleteImages.push(image.url);
      image._destroy = true;
    } else {
      newMessage.images_attributes.splice(index, 1);
    }
    this.setState({ message: newMessage, deleteImages: newDeleteImages });
  }

  submit = async () => {
    const { office_id } = this.props.context.appData;
    const { message, deleteImages } = this.state;
    const data = { scheduled_message: { ...message }, delete_images: deleteImages };
    for (let i = 0; i < data.scheduled_message.images_attributes.length; i++) {
      const image = data.scheduled_message.images_attributes[i];
      if (!image.id) {
        const url = await uploadImage({ ...this.base, fileName: image.url.file.name, file: image.url.file });
        data.scheduled_message.images_attributes[i] = { url };
      }
    }
    const res = !data.scheduled_message.id
      ? await apiPost({ ...this.base, endpoint: `/offices/${office_id}/scheduled_messages`, data })
      : await apiPut({ ...this.base, endpoint: `/offices/${office_id}/scheduled_messages/${message.id}`, data });
    if (!res.error) {
      const newMessages = [...this.state.messages];
      if (data.scheduled_message.id) {
        const index = newMessages.findIndex(message => message.id === data.scheduled_message.id);
        newMessages[index] = res;
      } else {
        newMessages.push(res);
      }
      this.setState({ messages: newMessages, modal: false }, () => {
        setTimeout(() => this.setState({ message: null }), 300);
      });
    } else {
      alert(res.error);
    }
  }

  destroy = async (message, index) => {
    const { office_id } = this.props.context.appData;
    const res = await apiDelete({ ...this.base, endpoint: `/offices/${office_id}/scheduled_messages/${message.id}` });
    if (!res.error) {
      const newMessages = [...this.state.messages];
      newMessages.splice(index, 1);
      this.setState({ messages: newMessages });
    } else {
      alert(res.error);
    }
  }

  render() {
    const { messages, message, modal, tmpImage } = this.state;

    return (
      <div className="uk-flex-1">
        <div className="uk-container uk-container-small">
          <div className="uk-text-center">
            <PageTitle title="子育て情報等定期配信一覧" />
            <div className="uk-text-right">
              <button
                className="uk-button uk-button-small uk-button-primary uk-button-rounded"
                onClick={() => this.setState({ message: this.blankMessage, modal: true })}>
                定期配信を作成
              </button>
            </div>
            <table className="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-small uk-text-left">
              <thead>
                <tr>
                  <th>作成日</th>
                  <th>条件</th>
                  <th>タイトル</th>
                  <th>本文</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {messages.map((msg, i) => (
                  <tr key={`messages-message-${i}`}>
                    <td className="uk-text-nowrap">{displayDate(msg.created_at)}</td>
                    <td className="uk-text-nowrap">{msg.years}歳 {msg.months}ヶ月</td>
                    <td className="uk-text-nowrap">{msg.title}</td>
                    <td>{clippedText(msg.body)}</td>
                    <td className="uk-text-nowrap uk-text-right">
                      <button
                        className="uk-button uk-button-small uk-button-link uk-margin-small-right"
                        onClick={() => this.setState({ message: msg, modal: true })}>
                        編集
                      </button>
                      <button
                        className="uk-button uk-button-small uk-button-link uk-text-danger"
                        onClick={() => {
                          if (confirm("削除してもよろしいですか？")) this.destroy(msg, i);
                        }}>
                        削除
                      </button>
                    </td>
                  </tr>
                ))}
                {messages.length === 0 && (
                  <tr>
                    <td colSpan={5} className="uk-text-center">表示できる定期配信情報がありません</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <Modal show={modal}>
          {message && (
            <>
              <div className="uk-modal-header uk-flex uk-flex-between">
                <h4 className="uk-margin-remove">定期配信情報を{message.id ? '編集' : '追加'}</h4>
                <button
                  className="uk-button-icon"
                  data-uk-icon="close"
                  onClick={() => {
                    this.setState({ modal: false }, () => {
                      setTimeout(() => this.setState({ message: null }), 300);
                    })
                  }} />
              </div>
              <div className="uk-modal-body uk-form-horizontal">
                <div>
                  <label className="uk-form-label uk-width-xsmall">条件 <span className="uk-text-danger">＊</span></label>
                  <div className="uk-form-controls uk-form-controls-xsmall">
                    <div className="uk-flex uk-flex-row uk-flex-middle">
                      <input
                        type="number"
                        className="uk-input uk-text-right uk-margin-small-right"
                        style={{ minWidth: 0, width: 60 }}
                        value={`${message.years}` ?? null}
                        onChange={e => this.setState({ message: { ...message, years: parseInt(e.target.value) } })}
                        placeholder="0" />
                      <span className="uk-margin-small-right uk-flex-none">歳</span>
                      <input
                        type="number"
                        className="uk-input uk-text-right uk-margin-small-right"
                        style={{ minWidth: 0, width: 60 }}
                        value={`${message.months}` ?? null}
                        onChange={e => this.setState({ message: { ...message, months: parseInt(e.target.value) } })}
                        placeholder="0" />
                      <span className="uk-flex-none">ヶ月 になったら<span className="uk-text-small uk-text-meta">（半角数字のみ）</span></span>
                    </div>
                  </div>
                </div>
                <div className="uk-margin-top">
                  <label className="uk-form-label uk-width-xsmall">タイトル <span className="uk-text-danger">＊</span></label>
                  <div className="uk-form-controls uk-form-controls-xsmall">
                    <input
                      type="text"
                      className="uk-input"
                      style={{ marginTop: 4 }}
                      value={message.title}
                      onChange={e => this.setState({ message: { ...message, title: e.target.value } })}
                      placeholder="タイトルを入力してください"/>
                  </div>
                </div>
                <div className="uk-margin">
                  <label className="uk-form-label uk-width-xsmall">本文 <span className="uk-text-danger">＊</span></label>
                  <div className="uk-form-controls uk-form-controls-xsmall">
                    <textarea
                      className="uk-textarea uk-height-small"
                      style={{ marginTop: 4 }}
                      value={message.body}
                      onChange={e => this.setState({ message: { ...message, body: e.target.value } })}
                      placeholder="本文を入力してください" />
                  </div>
                </div>
                <div className="uk-margin-top">
                  <label className="uk-form-label uk-width-xsmall">URL</label>
                  <div className="uk-form-controls uk-form-controls-xsmall">
                    <input
                      type="text"
                      className="uk-input"
                      style={{ marginTop: 4 }}
                      value={message.url}
                      onChange={e => this.setState({ message: { ...message, url: e.target.value } })}
                      placeholder="https://" />
                  </div>
                </div>
                <div className="uk-margin-top">
                  <label className="uk-form-label uk-width-xsmall">画像（3枚まで）</label>
                  <div className="uk-form-controls uk-form-controls-xsmall">
                    <div className="uk-grid-small uk-child-width-1-3@s" data-uk-grid>
                      {message.images_attributes.filter(img => !img._destroy).map((image, i) => {
                        return (
                          <div key={`image-${i}`}>
                            <div className="uk-flex-1 uk-position-relative uk-flex uk-flex-center uk-flex-middle uk-background-secondary uk-height-1-1 uk-background-secondary">
                              <img
                                src={image.id ? image.url : image.url.url} alt=""
                                className="uk-width-1-1" />
                              <button
                                onClick={e => {
                                  if (window.confirm("画像を削除してもよろしいですか？")) this.removeImage(i);
                                }}
                                className="uk-icon-button uk-position-absolute"
                                uk-icon="trash"
                                style={{ top: 8, right: 8 }} />
                            </div>
                          </div>
                        )
                      })}
                      {message.images_attributes.filter(img => !img._destroy).length < 3 && (
                        <div>
                          <div
                            data-uk-form-custom
                            onChange={e => {
                              this.setState({ tmpImage: e.target.files[0] });
                              e.target.value = null;
                            }}
                            className="uk-placeholder uk-flex uk-flex-row uk-flex-center uk-flex-middle uk-height-small uk-flex-1 uk-margin-remove-top uk-margin-remove-bottom">
                            <input type="file" />
                            <span uk-icon="icon: plus" className="uk-text-muted" />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="uk-modal-footer uk-text-right">
                <button
                  className="uk-button uk-button-default uk-margin-small-right uk-button-rounded"
                  onClick={() => {
                    this.setState({ modal: false }, () => {
                      setTimeout(() => this.setState({ message: null }), 300);
                    });
                  }}>
                  キャンセル
                </button>
                <button
                  className="uk-button uk-button-primary uk-button-rounded"
                  disabled={typeof(message.years) !== 'number' || typeof(message.months) !== 'number' || !message.title || !message.body}
                  onClick={this.submit}>
                  保存
                </button>
              </div>
            </>
          )}
        </Modal>

        <ImageCropper
          source={tmpImage}
          options={{ unit: '%', width: 100, aspect: (1 / 1) }}
          onClose={() => this.setState({ tmpImage: null })}
          onComplete={this.addImage} />

      </div>
    );
  }
}

const ScheduledMessagesWithContext = withContext(ScheduledMessages);
export { ScheduledMessagesWithContext as ScheduledMessages };
