import React from 'react';
import { Route, Switch } from "react-router-dom";
import { Login } from "./components/auth/login";
import { MainMenu } from "./components/mainMenu/mainMenu";
import { Admin } from "./components/admin/admin";
import { withContext } from "./context";
import { Header } from "./components/header/header";
import { NewAccount } from "./components/admin/newAccount";
import { NewOrEdit } from "./components/posts/newOrEdit";
import { Settings } from "./components/settings/settings";
import { Posts } from './components/posts/posts';
import { Footer } from './components/footer/footer';
import { OfficeUsers } from './components/officeUsers/officeUsers';
import { TrashAreas } from './components/trashAreas/trashAreas';
import { ScheduledMessages } from './components/scheduledMessages/scheduledMessages';
import { TermsOfUse } from './components/policies/termsOfUse';
import { PrivacyPolicy } from './components/policies/privacyPolicy';
import { Overview } from './components/overview/overview';
import { UserInfo } from './components/userInfo/userInfo';
import { AllPosts } from './components/admin/allPosts';

function App({ context }) {
  return (
    <>
      {context.appData.authToken && <Header />}
      <Switch>
        {context.appData.authToken ? (
          <>
            <Route exact path="/terms_of_use" component={TermsOfUse} />
            <Route exact path="/privacy_policy" component={PrivacyPolicy} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/admin/new" component={NewAccount} />
            <Route exact path="/admin/posts" component={AllPosts} />
            <Route exact path="/user" component={UserInfo} />
            <Route exact path="/posts" component={Posts} />
            <Route exact path="/posts/new" component={NewOrEdit} />
            <Route exact path="/posts/:post_id/edit" component={NewOrEdit} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/trash" component={TrashAreas} />
            <Route exact path="/scheduled_posts" component={ScheduledMessages} />
            <Route exact path="/users" component={OfficeUsers} />
            <Route exact path="/" component={MainMenu} />
          </>
        ) : (
          <>
            <Route exact path="/overview" component={Overview} />
            <Route exact path="/terms_of_use" component={TermsOfUse} />
            <Route exact path="/privacy_policy" component={PrivacyPolicy} />
            <Route exact path="/" component={Login} />
          </>
        )}
      </Switch>
      <Footer hidden={context.hiddenFooter} />
    </>
  );
}

const AppWithContext = withContext(App);
export default AppWithContext;
