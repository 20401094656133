import React, { PureComponent } from 'react';
import { apiGet } from '../../utils/api';
import { displayDate } from '../../utils/utils';
import divider from '../../assets/divider.png';

class Overview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
    };
  }

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const res = await apiGet({ endpoint: '/posts/overview' });
    !res.error
      ? this.setState({ posts: res })
      : alert(res.error);
  }

  render() {
    const { posts } = this.state;

    return (
      <div className="uk-flex uk-flex-column uk-flex-middle" style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}>
        {posts.map((post, index) => (
          <div
            key={`overview-post-${index}`}
            style={{
              border: 'solid 1px',
              borderWidth: 3,
              borderColor: '#3FDECE',
              borderRadius: 10,
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 16,
              paddingRight: 16,
              maxWidth: 460,
              marginTop: 16,
            }}>
            <img src={divider} style={{ marginBottom: 10 }} />
            <p className="uk-margin-remove uk-text-small uk-text-meta">{displayDate(post.created_at)}</p>
            <p className="uk-margin-remove uk-text-bold uk-text-small uk-text-secondary">{post.publisher.name}</p>
            <hr style={{ marginTop: 8, marginBottom: 8 }} />
            {!!post.title && <p className="uk-margin-remove-bottom" style={{ marginTop: 6 }}>{post.title}</p>}
            {!!post.images.length && (
              <img src={post.images[0].url} style={{ marginTop: 6 }} className="uk-border-rounded" />
            )}
            <p className="uk-margin-remove-bottom" style={{ marginTop: 6 }}>{post.body}</p>
            <hr style={{ marginTop: 10, marginBottom: 8 }} />
            <div className="uk-text-right">
              <a href="#" className="uk-text-small" onClick={() => !!window.ReactNativeWebView && window.ReactNativeWebView.postMessage('showMessage')}>
                続きを見る
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export { Overview };