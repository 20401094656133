import React, { useEffect, useState } from 'react';

const Modal = ({ show, small, children }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => setActive(true), 100);
    } else {
      setActive(false);
    }
  }, [show]);

  return show ? (
    <div className={['overlay', active ? 'active' : ''].join(' ')}>
      <div className={['modal uk-box-shadow-small uk-border-rounded uk-overflow-hidden uk-text-small', active ? 'active' : '', small ? 'small' : ''].join(' ')}>
        {children}
      </div>
    </div>
  ) : null;
};

export { Modal };
