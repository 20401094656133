import React from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({ children, to, className }) => (
  <Link
    to={to}
    className={['uk-width-large uk-button menu-item uk-text-bold', className].join(' ')}
    style={{ paddingTop: 8, paddingBottom: 8 }}>
    {children}
    <span uk-icon="icon: triangle-right; ratio: 1.5;" className="menu-item-icon"></span>
  </Link>
);

export { MenuItem };
