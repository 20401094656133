import React from 'react';

const Paginator = ({ currentPage, totalPages, onPrev, onNext }) => {
  return (
    <div className="uk-flex uk-flex-row uk-flex-between">
      <div
        className={`uk-flex uk-flex-row uk-flex-middle ${currentPage > 1 ? 'uk-link' : 'uk-text-muted'}`}
        onClick={currentPage > 1 ? onPrev : null}>
        <span data-uk-icon="icon: arrow-left"></span>
        <span className="uk-text-small" style={{ lineHeight: 1 }}>前へ</span>
      </div>
      <div
        className={`uk-flex uk-flex-row uk-flex-middle ${currentPage < totalPages ? 'uk-link' : 'uk-text-muted'}`}
        onClick={currentPage < totalPages ? onNext : null}>
        <span className="uk-text-small" style={{ lineHeight: 1 }}>次へ</span>
        <span data-uk-icon="icon: arrow-right"></span>
      </div>
    </div>
  );
};

export { Paginator };